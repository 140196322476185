/** @jsx jsx */
import {
  jsx,
  Box,
  Select,
  Label,
  Flex,
  Container,
  Text,
  Spinner,
} from "theme-ui";
import { useState, useEffect, useCallback } from "react";
import {
  DefaultButton,
  PivotItem,
  Pivot,
  ActionButton,
} from "office-ui-fabric-react";
import {
  DetailsList,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import {
  Dialog,
  DialogType,
  DialogContent,
} from "office-ui-fabric-react/lib/Dialog";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { buttonStyles, inputStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import HtmlDiv from "sites-common/components/HtmlDiv";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import { get } from "lodash";
import handleError from "sites-common/utils/handleError";
import { appsDialogStyles } from "../utils/dialogStyles";
import TemplateSelection from "./TemplateSelection";
import AddEmailTemplate from "./AddEmailTemplate";

const DashboardMail = ({ eventid, eventname }) => {
  const [eventId] = useState(eventid);
  const [eventName] = useState(eventname);
  const [tempSelected, setTempSelected] = useState(0);
  const [tempDetail, setTempDetail] = useState({});
  const [sendMailConfirm, setsendMailConfirm] = useState(false);
  const [success, setSuccess] = useState("");
  const [updating, setUpdating] = useState(false);

  const { fetchSrcmApi } = useFetchSrcmApi();
  const { showAlert } = useAlert();

  const [editTemplate, setEditTemplate] = useState({});
  const [emailTempList, setEmailTempList] = useState([]);
  const [addEmailTempDialog, setAddEmailTempDialog] = useState(false);
  const openAddEmailTempDialog = () => setAddEmailTempDialog(true);
  const closeAddEmailTempDialog = () => {
    setEditTemplate({});
    setAddEmailTempDialog(false);
  };

  const [smsTempList, setSmsTempList] = useState([]);
  const [addSmsTempDialog, setAddSmsTempDialog] = useState(false);
  const openAddSmsTempDialog = () => setAddSmsTempDialog(true);
  const closeAddSmsTempDialog = () => {
    setEditTemplate({});
    setAddSmsTempDialog(false);
  };

  const openMailModal = () => setsendMailConfirm(true);
  const closeMailModal = () => setsendMailConfirm(false);

  const emailInitColumns = [
    {
      key: "id",
      fieldName: "id",
      name: "Template Id",
      data: "string",
      isResizable: true,
      maxWidth: 200,
    },
    {
      key: "sender_email",
      fieldName: "sender_email",
      name: "Sender Email",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "sender_email_name",
      fieldName: "sender_email_name",
      name: "Sender Name",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "subject",
      fieldName: "subject",
      name: "Subject",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "body_html",
      fieldName: "body_html",
      name: "Content",
      data: "string",
      isResizable: true,
    },
    {
      key: "trigger_value",
      fieldName: "trigger_value",
      name: "Trigger",
      data: "string",
      isResizable: true,
      maxWidth: 300,
    },
    {
      key: "action",
      fieldName: "action",
      name: "Action",
      data: "string",
      isResizable: true,
      minWidth: 100,
      maxWidth: 100,
      onRender: (item) => (
        <Flex>
          <ActionButton
            iconProps={{ iconName: "Edit" }}
            text="Edit"
            onClick={() => {
              setEditTemplate(item);
              openAddEmailTempDialog();
            }}
          />
        </Flex>
      ),
    },
  ];

  const smsInitColumns = [
    {
      key: "id",
      fieldName: "id",
      name: "Template Id",
      data: "string",
      isResizable: true,
      minWidth: 200,
      maxWidth: 400,
    },
    {
      key: "trigger_value",
      fieldName: "trigger_value",
      name: "Trigger Value",
      data: "string",
      isResizable: true,
      minWidth: 200,
    },
    {
      key: "action",
      fieldName: "action",
      name: "Action",
      data: "string",
      isResizable: true,
      minWidth: 200,
      onRender: (item) => (
        <Flex>
          <ActionButton
            iconProps={{ iconName: "Edit" }}
            text="Edit"
            onClick={() => {
              setEditTemplate(item);
              openAddSmsTempDialog();
            }}
          />
        </Flex>
      ),
    },
  ];

  const getAllEmailTemplates = useCallback(() => {
    fetchSrcmApi({
      api: `/api/v3/events/${eventId}/emails/`,
      client: "eventsClient",
    }).then((data) => {
      setEmailTempList(data.results);
    });
  }, [eventId, fetchSrcmApi]);

  const getAllSmsTemplates = useCallback(() => {
    fetchSrcmApi({
      api: `/api/v3/events/${eventId}/sms/`,
      client: "eventsClient",
    }).then((data) => {
      setSmsTempList(data.results);
    });
  }, [eventId, fetchSrcmApi]);

  const readTemplate = useCallback(() => {
    fetchSrcmApi({
      api: `/api/v3/events/${eventId}/emails/${tempSelected}/`,
      client: "eventsClient",
    }).then((data) => {
      setTempDetail(data);
    });
  }, [eventId, tempSelected, fetchSrcmApi]);

  const sendMail = (event) => {
    event.preventDefault();
    setsendMailConfirm(false);
    setUpdating(true);
    const afterUpdate = () => {
      setUpdating(false);
    };
    fetchSrcmApi({
      api: `/api/v3/events/${eventId}/emails/${tempDetail.id}/send/`,
      method: "POST",
      client: "eventsClient",
    })
      .then((data) => {
        setSuccess(data);
        return afterUpdate();
      })
      .catch((error) => {
        const alertInfo = {
          title: "Error",
          message: handleError(error),
          confirm_text: "Okay",
          is_blocking: true,
        };
        setUpdating(false);
        showAlert(alertInfo);
      });
  };

  useEffect(() => {
    getAllEmailTemplates();
    getAllSmsTemplates();
  }, [eventId, getAllEmailTemplates, getAllSmsTemplates]);

  useEffect(() => {
    if (tempSelected !== 0) {
      readTemplate();
    }
  }, [tempSelected, eventId, readTemplate]);

  const renderEmailItemColumn = (item, index, column) => {
    const fieldContent =
      column && column.fieldName ? item[column.fieldName] : "";
    switch (column.key) {
      case "id":
        return `Template #${fieldContent}`;
      case "sender_email":
        return fieldContent || "-";
      case "sender_email_name":
        return fieldContent || "-";
      case "subject":
        return fieldContent || "-";
      case "body_html":
        return <Text dangerouslySetInnerHTML={{ __html: fieldContent }} />;
      case "trigger_value":
        return fieldContent || "-";
      default:
        return index;
    }
  };

  const renderSmsItemColumn = (item, index, column) => {
    const fieldContent =
      column && column.fieldName ? item[column.fieldName] : "";

    switch (column.key) {
      case "id":
        return `Template #${fieldContent}`;
      case "trigger_value":
        return fieldContent || "-";
      default:
        return index;
    }
  };

  return (
    <Container>
      <Pivot>
        <PivotItem headerText="Email Templates">
          <Box sx={{ p: 2, textAlign: "right" }}>
            <DefaultButton
              styles={buttonStyles.blueLight}
              onClick={openAddEmailTempDialog}
            >
              Add Template{" "}
            </DefaultButton>
          </Box>
          <Box>
            {emailTempList && emailTempList.length ? (
              <DetailsList
                columns={emailInitColumns}
                items={emailTempList}
                setKey="set"
                selectionMode={SelectionMode.none}
                onRenderItemColumn={renderEmailItemColumn}
                compact
                enableUpdateAnimations
              />
            ) : (
              <Box sx={{ textAlign: "center", p: 3 }}>
                <h3>No email templates found.</h3>
              </Box>
            )}
          </Box>
        </PivotItem>
        <PivotItem headerText="SMS Templates">
          <Box sx={{ p: 2, textAlign: "right" }}>
            <DefaultButton
              styles={buttonStyles.blueLight}
              onClick={openAddSmsTempDialog}
            >
              Add Template{" "}
            </DefaultButton>
          </Box>
          <Box>
            {smsTempList && smsTempList.length ? (
              <DetailsList
                columns={smsInitColumns}
                items={smsTempList}
                setKey="set"
                selectionMode={SelectionMode.none}
                onRenderItemColumn={renderSmsItemColumn}
                compact
                enableUpdateAnimations
              />
            ) : (
              <Box sx={{ textAlign: "center", p: 3 }}>
                <h3>No sms templates found.</h3>
              </Box>
            )}
          </Box>
        </PivotItem>
        <PivotItem headerText="Send Emails">
          {emailTempList && emailTempList.length ? (
            <Box sx={{ p: 2 }}>
              <Box>
                <Label>Template:</Label>
                <Select
                  mb={3}
                  sx={inputStyles.inputStyle}
                  value={tempSelected}
                  onChange={(e) => setTempSelected(e.target.value)}
                >
                  <option value={0} disabled sx={{ color: "#848484" }}>
                    Choose your template
                  </option>
                  {emailTempList &&
                    emailTempList.map((item) => (
                      <option key={item.subject} value={item.id}>
                        {item.subject}
                      </option>
                    ))}
                </Select>
              </Box>
              <Box>
                <Label>Preview:</Label>
                <Box sx={{ background: "#dddddd", p: 3, my: 3 }}>
                  <Box>
                    <strong>Subject: </strong>
                    {tempDetail.subject && tempDetail.subject}
                  </Box>
                  <Box my={2}>
                    <HtmlDiv htmlString={tempDetail.body_html} />
                  </Box>
                </Box>
              </Box>
              <Flex mb={4} sx={{ gap: 2, float: "right" }}>
                {updating && <Spinner size={35} />}
                <DefaultButton
                  disabled={updating || !tempSelected}
                  styles={buttonStyles.blueLight}
                  onClick={openMailModal}
                >
                  Send Mail
                </DefaultButton>
              </Flex>
            </Box>
          ) : (
            <Box sx={{ textAlign: "center", py: 5 }}>
              <h3>No send emails found.</h3>
            </Box>
          )}
          {success && success.message && (
            <Box sx={{ px: 2 }}>
              <Text sx={inputStyles.successMessage}>
                <strong>Success: </strong>
                {success.message}
              </Text>
            </Box>
          )}
        </PivotItem>
      </Pivot>

      {/* Add SMS Template  */}
      <Dialog
        isOpen={addSmsTempDialog}
        type={DialogType.close}
        onDismiss={closeAddSmsTempDialog}
        title={
          get(editTemplate, "id") ? "Edit SMS Template" : "Add SMS Template"
        }
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.mediumX}
      >
        <Box>
          <TemplateSelection
            eventId={eventId}
            eventName={eventName}
            refreshTemplates={getAllSmsTemplates}
            closeModal={closeAddSmsTempDialog}
            initValue={editTemplate}
          />
        </Box>
      </Dialog>

      {/* Add Email Template  */}
      <Dialog
        isOpen={addEmailTempDialog}
        type={DialogType.close}
        onDismiss={closeAddEmailTempDialog}
        title={
          get(editTemplate, "id") ? "Edit Email Template" : "Add Email Template"
        }
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.mediumX}
      >
        <Box>
          <AddEmailTemplate
            eventId={eventid}
            refreshTemplates={getAllEmailTemplates}
            closeModal={closeAddEmailTempDialog}
            initValue={editTemplate}
          />
        </Box>
      </Dialog>

      <Dialog
        isOpen={sendMailConfirm}
        type={DialogType.close}
        onDismiss={closeMailModal}
        title="Are you sure want to send the mail?"
        subText=""
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.medium}
      >
        <DialogContent>
          <Flex sx={{ gap: 3, float: "right" }}>
            <DefaultButton
              styles={buttonStyles.default}
              onClick={closeMailModal}
            >
              No
            </DefaultButton>
            <DefaultButton styles={buttonStyles.blueLight} onClick={sendMail}>
              Yes
            </DefaultButton>
          </Flex>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

DashboardMail.propTypes = {
  eventid: PropTypes.string.isRequired,
  eventname: PropTypes.string.isRequired,
};

export default DashboardMail;
