import React from "react";
import PropTypes from "prop-types";
import { Box, Text } from "theme-ui";

const Heading = ({ show, heading }) =>
  show && (
    <Box
      sx={{
        margin: "30px 0",
      }}
    >
      <Text
        variant="inverted"
        sx={{
          fontSize: 4,
          fontWeight: "bold",
          lineHeight: 1.2,
        }}
        as="h1"
      >
        {heading}
      </Text>
    </Box>
  );

Heading.propTypes = {
  show: PropTypes.bool,
  heading: PropTypes.string,
};

Heading.propTypes = {
  show: PropTypes.bool,
  heading: PropTypes.any,
};

export default React.memo(Heading);
