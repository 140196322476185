/** @jsx jsx */
import { jsx, Flex, Box, Text, Card, Grid, Container } from "theme-ui";
import React, { useState, useEffect } from "react";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import unCamelCase from "sites-common/utils/unCamelCase";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ data }) => {
  // const styles = {
  //   relative: {
  //     position: "relative",
  //     width: 400
  //   }
  // };

  return (
    <Container sx={{ pb: 4 }}>
      <Box
        sx={{
          px: 2,
          py: 1,
          mb: 3,
          fontSize: "22px",
        }}
      >
        Stats Graphs:
      </Box>
      <Grid
        columns={[2, "400px 400px"]}
        sx={{
          justifyContent: "space-evenly",
          "@media (max-width: 480px)": {
            gridTemplateColumns: ["repeat(1, 300px)"],
            gridGap: 3,
          },
        }}
      >
        {Object.keys(data).map((key) => {
          const { labels, dataSets, bgColors } = data[key];
          return (
            key !== "city" &&
            key !== "state" &&
            key !== "gender" && (
              <Box key={key}>
                <Doughnut
                  data={{
                    responsive: true,
                    labels,
                    datasets: [
                      {
                        data: dataSets,
                        backgroundColor: bgColors,
                        hoverBackgroundColor: bgColors,
                      },
                    ],
                  }}
                  options={{
                    legend: {
                      display: false,
                    },
                  }}
                />
                <Box sx={{ textAlign: "center", py: 3 }}>
                  {unCamelCase(key)}
                </Box>
              </Box>
            )
          );
        })}
      </Grid>
    </Container>
  );
};

DoughnutChart.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const CardCounts = ({ data }) => {
  const { status, gender, country, state, city } = data;
  const style = {
    cardStyle: {
      minHeight: 360,
      textAlign: "center",
      backgroundColor: "#F7FBFF",
      color: "black",
      boxShadow: "0px 3px 6px #00000029",
      "@media (max-width: 480px)": {
        height: "max-content",
      },
    },
    innerCardItem: {
      width: "90%",
      margin: "0 auto",
      textAlign: "left",
      background: "#e2f2ff8f",
      padding: "0.25rem 0.5rem",
      marginBottom: "0.5rem",
    },
    cardNumber: {
      fontSize: "32px",
      letterSpacing: "2px",
    },
    cardText: {
      fontSize: "0.875rem",
    },
  };

  return (
    <Container>
      <Grid
        columns={[5, "1fr 1fr 1fr 1fr 1fr"]}
        sx={{
          mb: 3,
          "@media (max-width: 480px)": {
            gridTemplateColumns: ["repeat(1, 1fr)"],
            gridGap: 3,
          },
        }}
      >
        <Box>
          <Card sx={style.cardStyle}>
            <Box
              sx={{
                backgroundColor: "#E2F2FF",
                color: "black",
                p: 3,
              }}
            >
              <b>Status</b>
            </Box>
            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
                p: 2,
              }}
            >
              <Box sx={style.innerCardItem}>
                <Box sx={style.cardNumber}>{get(status, "pending", 0)}</Box>
                <Box sx={style.cardText}>Pending</Box>
              </Box>
              <Box sx={style.innerCardItem}>
                <Box sx={style.cardNumber}>{get(status, "confirmed", 0)}</Box>
                <Box sx={style.cardText}>Confirmed</Box>
              </Box>
              <Box sx={style.innerCardItem}>
                <Box sx={style.cardNumber}>{get(status, "cancelled", 0)}</Box>
                <Box sx={style.cardText}>Cancelled</Box>
              </Box>
            </Box>
          </Card>
        </Box>
        <Box>
          <Card sx={style.cardStyle}>
            <Box
              sx={{
                backgroundColor: "#E2F2FF",
                color: "black",
                p: 3,
              }}
            >
              <b>Gender</b>
            </Box>
            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
                p: 2,
              }}
            >
              <Box sx={style.innerCardItem}>
                <Box sx={{ fontSize: "40px", letterSpacing: "2px" }}>
                  {get(gender, "M", 0)}
                </Box>
                <Box sx={style.cardText}>Male</Box>
              </Box>
              <Box sx={style.innerCardItem}>
                <Box sx={style.cardNumber}>{get(gender, "F", 0)}</Box>
                <Box sx={style.cardText}>Female</Box>
              </Box>
              <Box sx={style.innerCardItem}>
                <Box sx={style.cardNumber}>{get(gender, "U", 0)}</Box>
                <Box sx={style.cardText}>Others</Box>
              </Box>
              {/* <Box sx={style.cardNumber}>
                {get(gender, "M", 0)}/{get(gender, "F", 0)}/
              </Box>
              <Box>M / F / Others</Box> */}
            </Box>
          </Card>
        </Box>
        <Box>
          <Card sx={style.cardStyle}>
            <Box
              sx={{
                backgroundColor: "#E2F2FF",
                color: "black",
                p: 3,
              }}
            >
              <b>Country</b>
            </Box>
            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
                p: 2,
              }}
            >
              <Box sx={style.cardNumber}>{get(country, "total", 0)}</Box>
              <Box>Countries</Box>
            </Box>
          </Card>
        </Box>
        <Box>
          <Card sx={style.cardStyle}>
            <Box
              sx={{
                backgroundColor: "#E2F2FF",
                color: "black",
                p: 3,
              }}
            >
              <b>State</b>
            </Box>
            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
                p: 2,
              }}
            >
              <Box sx={style.cardNumber}>{get(state, "total", 0)}</Box>
              <Box>States</Box>
            </Box>
          </Card>
        </Box>
        <Box>
          <Card sx={style.cardStyle}>
            <Box
              sx={{
                backgroundColor: "#E2F2FF",
                color: "black",
                p: 3,
              }}
            >
              <b>City</b>
            </Box>
            <Box
              sx={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                flexDirection: "column",
                p: 2,
              }}
            >
              <Box sx={style.cardNumber}>{get(city, "total", 0)}</Box>
              <Box>Cities</Box>
            </Box>
          </Card>
        </Box>
      </Grid>
    </Container>
  );
};

CardCounts.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const CardCountsDetail = ({ data }) => {
  return (
    <Container sx={{ pb: 3 }}>
      <Box
        sx={{
          px: 2,
          py: 1,
          mb: 3,
          fontSize: "22px",
        }}
      >
        Stats Details:
      </Box>
      <Grid
        columns={[3, "2fr 2fr 2fr"]}
        sx={{
          "@media (max-width: 480px)": {
            gridTemplateColumns: ["repeat(1, 1fr)"],
            gridGap: 3,
          },
        }}
      >
        {Object.keys(data).map((title) => (
          <Box
            key={title}
            sx={{
              height: "350px",
              overflowY: "scroll",
              "::-webkit-scrollbar": {
                width: "0px",
                background: "transparent",
              },
              borderRadius: "5px",
              "scrollbar-width": "none",
              "-ms-overflow-style": "none",
              boxShadow: "0px 3px 6px #00000029",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#E2F2FF",
                p: 3,
                textAlign: "center",
                position: "sticky",
                top: 0,
              }}
            >
              <Text sx={{ fontWeight: "bold", fontSize: 2 }}>
                {unCamelCase(title)}
              </Text>
            </Box>
            {Object.keys(data[title]).map((prop) =>
              prop !== "null" &&
              prop !== "labels" &&
              prop !== "dataSets" &&
              prop !== "bgColors" &&
              prop !== "total" &&
              prop !== "sum" ? (
                <Flex
                  key={prop}
                  sx={{ px: 3, py: 2, borderBottom: "1px solid #eeeeee" }}
                >
                  <Box sx={{ flex: "1 1 auto" }}>{unCamelCase(prop)}</Box>
                  <Box>{data[title][prop]} </Box>
                </Flex>
              ) : null
            )}
          </Box>
        ))}
      </Grid>
    </Container>
  );
};

CardCountsDetail.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const DetailCard = ({ eventid }) => {
  const [statsProperties] = useState([
    "status",
    "gender",
    "country",
    "state",
    "city",
  ]);
  const [statsCounts, setStatsCounts] = useState({});
  const { fetchSrcmApi } = useFetchSrcmApi();
  const sumValues = (obj) =>
    Object.keys(obj).length !== 0 && Object.values(obj).reduce((a, b) => a + b);

  const dynamicColors = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgb(${r},${g},${b})`;
  };

  useEffect(() => {
    fetchSrcmApi({
      api: `/api/v3/events/${eventid}/stats/`,
      client: "eventsClient",
    }).then((data) => {
      if (data.results.length !== 0) {
        // const res = [
        //   ...new Set(Object.keys(data.results[0]).map((item) => item)),
        // ];
        let result = {};
        statsProperties.map((title) => {
          const res = data.results.reduce((acc, o) => {
            if (o[title] !== null) {
              acc[o[title]] = (acc[o[title]] || 0) + o.count;
              return acc;
            }
            return acc;
          }, {});

          result = {
            ...result,
            [title]: res,
          };
          return result;
        });

        Object.keys(result).map((resProp) => {
          result[resProp] = {
            total: Object.keys(result[resProp]).length,
            sum: sumValues(result[resProp]),
            labels: Object.keys(result[resProp]),
            dataSets: Object.values(result[resProp]),
            bgColors: Object.keys(result[resProp]).map(() => {
              return dynamicColors();
            }),
            ...result[resProp],
          };
          return result;
        });
        setStatsCounts(result);
      }
    });
  }, [eventid, statsProperties, fetchSrcmApi]);

  return (
    <Container py={3} px={2}>
      {Object.keys(statsCounts).length !== 0 ? (
        <div>
          <CardCounts data={statsCounts} />
          <DoughnutChart data={statsCounts} />
          <CardCountsDetail data={statsCounts} />
        </div>
      ) : (
        <Box sx={{ textAlign: "center", p: 3 }}>
          <h3>No stats information found.</h3>
        </Box>
      )}
    </Container>
  );
};

DetailCard.propTypes = {
  eventid: PropTypes.string.isRequired,
};

export default React.memo(DetailCard);
