import React from "react";
import PropTypes from "prop-types";
import { Text } from "theme-ui";

const FormMessagesLayout = ({ show, borderColor, message, variant }) =>
  show ? (
    <Text
      variant={variant}
      as="p"
      sx={{
        padding: "10px",
        textAlign: "center",
        fontSize: 1,
        borderWidth: "thin",
        borderStyle: "solid",
        borderColor,
      }}
    >
      {message}
    </Text>
  ) : null;

FormMessagesLayout.propTypes = {
  show: PropTypes.bool,
  borderColor: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.string,
};

const FormMessages = ({ show, variant, message }) => {
  const formMessages = {
    show,
    variant,
    message,
    borderColor: React.useMemo(() => {
      if (variant === "inverted") return "transparent";
      return "background";
    }, [variant]),
  };

  return <FormMessagesLayout {...formMessages} />;
};

FormMessages.propTypes = {
  show: PropTypes.bool,
  variant: PropTypes.oneOf(["inverted", "success", "danger"]),
  message: PropTypes.string,
};

export default React.memo(FormMessages);
