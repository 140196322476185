import React from "react";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { DefaultButton } from "office-ui-fabric-react";
import { buttonStyles } from "sites-common/utils/fabricStyles";
// import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import PropTypes from "prop-types";
import { setupRegFormFields } from "./crud-shared/eventFormFields";

const submitButton = (
  <DefaultButton type="submit" text="Submit" styles={buttonStyles.blueLight} />
);

const discardButton = (
  <DefaultButton type="submit" text="Discard" styles={buttonStyles.default} />
);

function SetupOnlineRegistration({ item, onSave, onDiscard }) {
  // const { showAlert } = useAlert();

  let regDateObj = {};
  if (!item.online_registration) {
    regDateObj = {
      reg_starts: null,
      reg_ends: null,
    };
  }
  const formDef = {
    fields: [...setupRegFormFields],
    defaults: { ...item, ...regDateObj },
  };

  const onSetupReg = (data, successCallback) => {
    const afterUpdate = () => {
      successCallback();
      onDiscard();
    };

    const afterFailure = () => {
      onDiscard();
    };

    onSave(data, afterUpdate, afterFailure, {
      use_method: "PATCH",
    });
  };

  return (
    <>
      <DynamicForm
        formClassName="form"
        formLayout={null}
        defaultValues={formDef.defaults}
        formFields={formDef.fields}
        // formValidator={formValidator}
        onSubmit={onSetupReg}
        onDiscard={onDiscard}
        buttons={{ submitButton, discardButton }}
      />
    </>
  );
}

SetupOnlineRegistration.defaultProps = {
  item: {},
  onSave: () => {},
  onDiscard: () => {},
};

SetupOnlineRegistration.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.func,
  onDiscard: PropTypes.func,
};

export default SetupOnlineRegistration;
