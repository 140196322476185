/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { Input, Box } from "theme-ui";
import { withTranslation } from "react-i18next";
import PhoneInput from "sites-common/components/ui/PhoneNumberInput/PhoneInput2";

const EmailInput = React.forwardRef(({ show, ...props }, ref) =>
  show ? (
    <Input
      variant="primary"
      placeholder="Email Address"
      name="email"
      sx={{ margin: "15px 0" }}
      {...props}
      ref={ref}
    />
  ) : null
);

const CustomPhoneInput = React.forwardRef(
  ({ show, value, onChange, ...props }, ref) =>
    show ? (
      <Box variant="boxes.primaryPhoneInput" sx={{ margin: "15px 0" }}>
        <PhoneInput
          country="in"
          enableSearch
          value={value}
          onChange={onChange}
          {...props}
        />

        <input
          hidden
          value={value}
          ref={ref}
          name="contactNumber"
          onChange={onChange}
        />
      </Box>
    ) : null
);

CustomPhoneInput.propTypes = {
  show: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

EmailInput.propTypes = {
  show: PropTypes.bool,
};

const ConditionalInputFieldLayout = ({ show, emailInput, customPhoneInput }) =>
  show ? (
    <Box>
      <EmailInput {...emailInput} />
      <CustomPhoneInput {...customPhoneInput} />
    </Box>
  ) : null;

ConditionalInputFieldLayout.propTypes = {
  emailInput: PropTypes.object,
  customPhoneInput: PropTypes.object,
  show: PropTypes.bool,
};

const ConditionalInputField = ({
  showEmailInput,
  showPhoneNumberInput,
  emailRef,
  phoneNumberRef,
  onChangePhoneNumber,
  t,
}) => {
  const conditionalInputField = {
    show: React.useMemo(
      () => showEmailInput || showPhoneNumberInput,
      [showEmailInput, showPhoneNumberInput]
    ),
    emailInput: {
      ref: emailRef,
      show: showEmailInput,
      placeholder: t("Email Address"),
    },
    customPhoneInput: {
      ref: phoneNumberRef,
      show: showPhoneNumberInput,
      onChange: React.useCallback(
        (...args) => {
          onChangePhoneNumber(...args);
        },
        [onChangePhoneNumber]
      ),
      placeholder: t("Mobile"),
    },
  };

  return <ConditionalInputFieldLayout {...conditionalInputField} />;
};

ConditionalInputField.propTypes = {
  showEmailInput: PropTypes.bool,
  showPhoneNumberInput: PropTypes.bool,
  emailRef: PropTypes.func,
  phoneNumberRef: PropTypes.func,
  onChangePhoneNumber: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(React.memo(ConditionalInputField));
