import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Label } from "theme-ui";
import Icon from "sites-common/components/ui/Icon";

const FileInput = ({ show, validationRef, label, variant, ...props }) => {
  if (!show) return null;
  return (
    <Box variant={variant} bg="primary">
      <Label>{label}</Label>
      <Flex className="field-wrapper">
        <input type="file" {...props} ref={validationRef} />
        <Icon icon="file" />
      </Flex>
    </Box>
  );
};

FileInput.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["boxes.fileinput"]),
  validationRef: PropTypes.oneOfType([
    PropTypes.shape({
      current: PropTypes.any,
    }),
    PropTypes.func,
  ]),
};

FileInput.defaultProps = {
  show: true,
  variant: "boxes.fileinput",
};

export default FileInput;
