import { Flex, Label } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";
import { noop } from "sites-common/utils/lodash";
import InputWithIcon from "sites-common/components/ui/InputWithIcon";

const InputWithIconAndLabel = React.forwardRef((props, ref) => {
  const {
    label = "",
    value = "",
    defaultValue = "",
    onChange = noop,
    ...restProps
  } = props;

  const ref1 = React.useRef(null);
  const inputRef = ref || ref1;

  const [state, setState] = React.useState({
    showLabel: false,
  });

  const onFocus = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showLabel: true,
    }));
  }, []);

  const onBlur = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showLabel: !!inputRef.current.value,
    }));
  }, [inputRef]);

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      showLabel: !!(value || defaultValue),
    }));
  }, [value, defaultValue]);

  return (
    <Flex sx={{ flexDirection: "column" }} onFocus={onFocus} onBlur={onBlur}>
      <Label
        sx={{
          minHeight: "14px",
          fontSize: "12px",
          lineHeight: "12px",
          p: "1px",
        }}
      >
        {state.showLabel && label}
      </Label>
      <InputWithIcon
        placeholder={state.showLabel ? "" : label}
        defaultValue={defaultValue}
        ref={inputRef}
        onChange={onChange}
        {...restProps}
      />
    </Flex>
  );
});

InputWithIconAndLabel.propTypes = {
  label: PropTypes.string,
  inputProps: PropTypes.shape({
    variant: PropTypes.oneOf(["border-bottom", "border-0", "primary"]),
  }),
  onChange: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
};

InputWithIconAndLabel.defaultProps = {
  label: "",
  value: "",
  defaultValue: "",
  onChange: () => {},
  inputProps: {},
};

export default InputWithIconAndLabel;
