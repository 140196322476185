// import React from "react";

import {
  selectorSrcmApiStoreDoc,
  selectorSrcmApiStoreOrdered,
} from "gatsby-plugin-hfn-profile/state/selectors";
import { set, get, uniq } from "lodash";
import { useUpperFirst } from "sites-common/utils/unCamelCase";
import { fetchSrcmApiStoreIfNeeded } from "gatsby-plugin-hfn-profile/state/actions_srcm";
import ScheduleCard from "./ScheduleCard";
import {
  eventFormFields,
  postprocess,
  eventFormDefaults,
  myEventsFormValidation,
  // eventFormvalidations,
} from "../../components/crud-shared/eventFormFields";

const eventsCollection = "srcmevents";

const sEventsId = (state, docId) => {
  let result = {};
  result = selectorSrcmApiStoreDoc(state, eventsCollection, docId);
  set(result, "paid_skus", get(result, ["event_json", "paid_skus"], []));
  const tags = get(result, ["tags"], []);
  if (tags.length !== 0) {
    set(result, "tag_strings", get(tags[0], ["name"]));
  }
  return result;
};

const selectorOptionsStatus = (state) => {
  return uniq(
    selectorSrcmApiStoreOrdered(state, eventsCollection).map((r) => r.status)
  ).map((x) => ({ key: x, text: useUpperFirst(x) }));
};

const selectorEventsForStatus = (state, status) =>
  status
    ? selectorSrcmApiStoreOrdered(state, eventsCollection)
        .filter((r) => r.status === status)
        .sort((a, b) => a.start_datetime <= b.start_datetime)
    : [];

const statusDispatcher = () =>
  fetchSrcmApiStoreIfNeeded(eventsCollection, { page: 1, page_size: 500 });

const Config = {
  sessionKey: "events-sk1",
  noItemMsg: "No Events Loaded",
  mainTitle: "Events Organized By Me",

  // different page configurations
  pageOptions: {
    All: {
      optionsSelector: () => [{ key: "All", text: "My Events" }],
      pageSelector: (state) =>
        selectorSrcmApiStoreOrdered(state, eventsCollection),
    },
    Status: {
      optionsSelector: selectorOptionsStatus,
      pageSelector: selectorEventsForStatus,
      pageDispatcher: statusDispatcher,
    },
  },

  collection: eventsCollection,
  primaryKey: "name",
  disableEditPrimaryKey: true,
  autogenPrimaryKey: true,

  showTableHeader: false,
  displayAsTable: false,
  renderItemCard: ScheduleCard,

  panelForEditItem: true,

  formFields: eventFormFields,
  formDefaults: eventFormDefaults,
  formValidator: myEventsFormValidation,
  postprocess,

  singleItemSelector: sEventsId,
  singleItemDispatcher: null,

  newBtnText: "Create New Event",
  newBtnIcon: "Add",

  // enableBulkUpload: true,
  editBtnText: "Edit Event",
  editBtnType: "action",
  // tableEditButtonProps: { text: "Edit" },
  // tableDeleteButtonProps: {text: "Delete"},

  filterProps: {
    placeholder: "Title / Preview",
    searchFunction: (r) => `${r.title} ${r.preview}`,
    filterMenuItems: [
      {
        key: "date",
        text: "Start Date (Recent First)",
        f: (a, b) => {
          if (a.start_datetime.toLowerCase() < b.start_datetime.toLowerCase()) {
            return 1;
          }
          if (b.start_datetime.toLowerCase() < a.start_datetime.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "title",
        text: "Name (Ascending)",
        f: (a, b) => {
          if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          }
          if (b.title.toLowerCase() > a.title.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "title",
        text: "Name (Decending)",
        f: (a, b) => {
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return 1;
          }
          if (b.title.toLowerCase() < a.title.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "group",
        text: "Group (Ascending)",
        f: (a, b) => {
          if (a.category.name.toLowerCase() > b.category.name.toLowerCase()) {
            return 1;
          }
          if (b.category.name.toLowerCase() > a.category.name.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
      {
        key: "group",
        text: "Group (Decending)",
        f: (a, b) => {
          if (a.category.name.toLowerCase() < b.category.name.toLowerCase()) {
            return 1;
          }
          if (b.category.name.toLowerCase() < a.category.name.toLowerCase()) {
            return -1;
          }
          return 0;
        },
      },
    ],
  },
};

export default Config;
