import React from "react";
import PropTypes from "prop-types";
import { Box } from "theme-ui";

const ModalWrapper = ({ open, children }) =>
  open ? (
    <Box
      sx={{
        minWidth: ["auto", 200],
        maxWidth: ["auto", 400],
        position: "fixed",
        top: 0,
        right: ["3px", "10%"],
        left: ["3px", "auto"],
      }}
    >
      {children}
    </Box>
  ) : null;

ModalWrapper.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.any,
};

export default React.memo(ModalWrapper);
