import applyProdQaConfig from "sites-common/utils/applyProdQaConfig";
import getFormattedDate from "sites-common/utils/FormatDate";

export const timeOptions = [
  { name: "00:00:00", label: "Midnight to 2AM" },
  { name: "02:00:00", label: "2AM to 4AM" },
  { name: "04:00:00", label: "4AM to 6AM" },
  { name: "06:00:00", label: "6AM to 8AM" },
  { name: "08:00:00", label: "8AM to 10AM" },
  { name: "10:00:00", label: "10AM to Noon" },
  { name: "12:00:02", label: "Noon to 2PM" },
  { name: "14:00:04", label: "2PM to 4PM" },
  { name: "16:00:06", label: "4PM to 6PM" },
  { name: "18:00:08", label: "6PM to 8PM" },
  { name: "20:00:10", label: "8PM to 10PM" },
  { name: "22:00:11", label: "10PM to Midnight" },
];

export const relationshipOption = [
  { name: "mother", label: "Mother" },
  { name: "father", label: "Father" },
  { name: "spouse", label: "Spouse" },
  { name: "brother", label: "Brother" },
  { name: "sister", label: "Sister" },
  { name: "friend", label: "Friend" },
];

export const modeOfTransport = [
  { name: "AIR", label: "Air" },
  { name: "TRAIN", label: "Train" },
  { name: "BUS", label: "Bus" },
  { name: "OWN-VEHICLE", label: "Own Vehicle" },
];

const currentDate = new Date();
// const bandaraStartDate = new Date(2022,01,01).getTime();
// const bandaraEndDate = new Date(2022,01,07).getTime();
const maxDate = new Date(currentDate).setMonth(currentDate.getMonth() + 6);

export function getDateRanges(bandara) {
  if (bandara) {
    const startDate = new Date(bandara.startDate);
    const endDate = new Date(bandara.endDate);
    const dateRange = [];
    while (startDate.getTime() < endDate.getTime()) {
      const sample = startDate.setDate(startDate.getDate() + 1);
      dateRange.push({
        name: getFormattedDate(sample, "dd-mm-yyyy"),
        label: getFormattedDate(sample, "shortDate"),
      });
    }
    return dateRange;
  }
  return null;
}

export const bhandaraJulyEventId = applyProdQaConfig({
  prod: {
    id: "bad34465-45e6-408c-827f-0b5de1626e3f",
  },
  qa: {
    id: "d4d6f8a1-5cf9-4bb6-87fb-965d6e70c971",
  },
});

export const februaryBhandaraEventId = applyProdQaConfig({
  prod: {
    id: "964f22fb-ac44-4716-88e6-4f7adab8a855",
  },
  qa: {
    id: "964f22fb-ac44-4716-88e6-4f7adab8a855",
  },
}).id;

export const AprilBhandaraEventId = applyProdQaConfig({
  prod: {
    id: "95450f32-65fd-4137-a151-cb43be73c182",
  },
  qa: {
    id: "95450f32-65fd-4137-a151-cb43be73c182",
  },
}).id;

export const bhandaraSept = "b403b5dc-816e-48b9-b3e5-ef3a966e735b";

export const styleProp = {
  registerBtn: {
    margin: "0.875rem 0",
    display: "inline-block",
    border: "#b0853a",
    borderRadius: "0.125rem",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#fff",
    pointerEvents: "auto",
    cursor: "pointer",
    background: "#b0853a",
    padding: "0.375rem 1.5rem",
    boxSizing: "content-box",
    width: "220px",
    letterSpacing: "2px",
    boxShadow: "0px 10px 30px #00000029",
    fontFamily: "arial,-apple-system,sans-serif !important",
    "&:hover": {
      background: "#b0853a",
      color: "#fff",
    },
  },
  comming_soon_btn: {
    margin: "0.75rem auto",
    display: "block",
    border: "#b1d1c0",
    borderRadius: "0.125rem",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#007c00",
    pointerEvents: "auto",
    background: "#b1d1c0",
    padding: "0.375rem 1.5rem",
    boxSizing: "content-box",
    width: "220px",
    letterSpacing: "2px",
    fontFamily: "arial,-apple-system,sans-serif !important",
  },
  reg_closed_btn: {
    margin: "0.75rem auto",
    display: "block",
    border: "#ffe8e8",
    borderRadius: "0.125rem",
    fontSize: "1rem",
    fontWeight: "500",
    color: "red",
    pointerEvents: "auto",
    background: "#ffe8e8",
    padding: "0.375rem 1.5rem",
    boxSizing: "content-box",
    width: "220px",
    letterSpacing: "2px",
    fontFamily: "arial,-apple-system,sans-serif !important",
  },
  event_closed_btn: {
    margin: "0.75rem auto",
    display: "block",
    border: "#ffe8e8",
    borderRadius: "0.125rem",
    fontSize: "1rem",
    fontWeight: "500",
    color: "red",
    pointerEvents: "auto",
    background: "#ffe8e8",
    padding: "0.375rem 1.5rem",
    boxSizing: "content-box",
    width: "220px",
    letterSpacing: "2px",
    fontFamily: "arial,-apple-system,sans-serif !important",
  },
};

export const panelStyle = {
  sidePanel: {
    background: "#fff",
  },
  addPersonBtn: {
    background: "#b0853a",
    borderColor: "none",
    color: "#fff",
  },
  registerBtn: {
    background: "#1c699f",
    color: "#fff",
  },
  discardBtn: {
    background: "#dee2e6",
    color: "#323130",
  },
};

export const ref = "B99999999";
const registrationOptions = {
  fields: [
    {
      name: "plan_a_stay",
      label: "Are you Planning to stay ",
      type: "toggle",
    },
    {
      name: "arrival_date",
      label: "Arrival Date",
      type: "date",
      props: {
        minDate: getFormattedDate(currentDate),
      },
    },
    {
      name: "arrival_time",
      label: "Arrival Time",
      type: "select",
      options: timeOptions,
      props: {
        style: {
          border: "1px solid #eeeeee",
        },
      },
    },
    {
      name: "departure_date",
      label: "Departure Date",
      type: "date",
      props: {
        minDate: getFormattedDate(currentDate),
        maxDate: getFormattedDate(maxDate),
        style: {
          alignItems: "center",
          placeItems: "center",
        },
      },
      dynamic: [["hide", [":plan_a_stay", "IS", "NULL"]]],
    },
    {
      name: "departure_time",
      label: "Departure Time",
      type: "select",
      options: timeOptions,
      props: {
        style: {
          border: "1px solid #eeeeee",
        },
      },
      dynamic: [["hide", [":plan_a_stay", "IS", "NULL"]]],
    },
    {
      name: "trainer_id",
      label: "Trainer ID",
      type: "text",
      props: {
        required: false,
      },
      dynamic: [["hide", [":plan_a_stay", "IS", "NULL"]]],

      // dynamic: [["hide", [[":plan_a_stay", "IS", "NULL"],  "&&", [[[bandaraStartDate, ">=", ":arrival_date"], "&&", [":arrival_date", "<=", bandaraEndDate]], "||", [[bandaraStartDate, ">=", ":departure_date"], "&&", [":departure_date", "<=", bandaraEndDate]]]
      // ]]],
    },
    {
      name: "trainer_name",
      label: "Trainer Name",
      type: "text",
      props: {
        required: false,
      },
      dynamic: [["hide", [":plan_a_stay", "IS", "NULL"]]],

      //   dynamic: [["hide", [[":plan_a_stay", "IS", "NULL"],  "&&", [[[bandaraStartDate, ">=", ":arrival_date"], "&&", [":arrival_date", "<=", bandaraEndDate]], "||", [[bandaraStartDate, ">=", ":departure_date"], "&&", [":departure_date", "<=", bandaraEndDate]]]
      // ]]],
    },
    {
      name: "trainer_number",
      label: "Trainer Contact Number",
      type: "phone",
      props: {
        required: false,
      },
      dynamic: [["hide", [":plan_a_stay", "IS", "NULL"]]],

      //   dynamic: [["hide", [[":plan_a_stay", "IS", "NULL"],  "&&", [[[bandaraStartDate, ">=", ":arrival_date"], "&&", [":arrival_date", "<=", bandaraEndDate]], "||", [[bandaraStartDate, ">=", ":departure_date"], "&&", [":departure_date", "<=", bandaraEndDate]]]
      // ]]],
    },
    {
      name: "emergency_contact_name",
      label: "Emergency Contact Name",
      type: "text",
    },
    {
      name: "emergency_contact_number",
      label: "Emergency Contact Number",
      type: "phone",
    },
  ],
  validator: [],
};

export const stayPreferenceOption = [
  { label: "East Premium dorm AC", name: "East Premium dorm AC" },
  { label: "East Premium dorm Non-AC", name: "East Premium dorm Non-AC" },
  { label: "South block Non AC", name: "South block Non AC" },
  { label: "Closed Tents AC", name: "Closed Tents AC" },
  {
    label: "Free accommodation North Non-AC",
    name: "Free accommodation North Non-AC",
  },
];

export function new_pnr(name) {
  const d = new Date();
  const n = d.getTime() - 1520000000000;
  let out = "";
  let itr = n;
  while (itr > 0) {
    const div = Math.floor(itr / 26);
    const rem = itr % 26;
    out = String.fromCharCode(65 + rem) + out;
    itr = div;
  }
  return `${name
    .replace(" ", "")
    .replace("-", "")
    .replace("_", "")
    .replace(".", "")
    .replace("@", "")
    .slice(0, 2)
    .toUpperCase()}-${out.slice(0, 4)}-${out.slice(
    out.length - 4,
    out.length
  )}`;
}

export const SHARE = "SHARE";

export const shareField = {
  name: "SHARE",
  sku: "SHARE",
  label: "Sharing with Child",
};

export const styles = {
  sidePanel: {
    background: "#fff",
  },
  addPersonBtn: {
    background: "#b0853a",
    borderColor: "none",
    color: "#fff",
  },
  registerBtn: {
    background: "#1c699f",
    color: "#fff",
  },
  discardBtn: {
    background: "#dee2e6",
    color: "#323130",
  },
};

export default registrationOptions;
