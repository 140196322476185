import React from "react";
import PropTypes from "prop-types";
import { Button } from "theme-ui";
import RegForm from "../RegForm";
import ModalWrapper from "../ModalWrapper";

const RegFormModal = ({
  heading,
  missedCallDetails,
  registerVia,
  buttonLabel,
  Trigger,
  onSubmit,
  validateEmail,
  validateContactNumber,
  theme,
  colors,
  language,
  fileInput,
}) => {
  const [state, setState] = React.useState({
    modalWrapper: {
      open: false,
    },
  });

  const updateModalWrapper = React.useCallback((update) => {
    setState((currentState) => ({
      ...currentState,
      modalWrapper: update(currentState.modalWrapper),
    }));
  }, []);

  const regForm = {
    language,
    heading,
    buttonLabel,
    registerVia,
    missedCallDetails,
    onSubmit,
    validateContactNumber,
    validateEmail,
    theme,
    colors,
    fileInput,
    onClose: React.useCallback(() => {
      updateModalWrapper((currentState) => ({
        ...currentState,
        open: false,
      }));
    }, []),
  };

  const modalWrapper = {
    ...state.modalWrapper,
  };

  const button = {
    variant: "variant2",
    onClick: React.useCallback((evt) => {
      evt.preventDefault();
      setState((currentState) => ({
        ...currentState,
        modalWrapper: {
          ...currentState.modalWrapper,
          open: !currentState.modalWrapper.open,
        },
      }));
    }, []),
  };

  return (
    <>
      <Trigger {...button}>Register Here</Trigger>
      <ModalWrapper {...modalWrapper}>
        <RegForm {...regForm} />
      </ModalWrapper>
    </>
  );
};

RegFormModal.propTypes = {
  open: PropTypes.bool,
  Trigger: PropTypes.any,
  registerVia: PropTypes.oneOf([
    "emailOnly",
    "mobileOnly",
    "emailOrMobile",
    "both",
  ]),
  buttonLabel: PropTypes.any,
  language: PropTypes.oneOf(["en", "fr", "hi"]),
  heading: PropTypes.any,
  missedCallDetails: PropTypes.shape({
    teleNumber: PropTypes.string,
    displayNumber: PropTypes.string,
    text: PropTypes.string,
  }),
  validateEmail: PropTypes.func,
  validateContactNumber: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  fileInput: PropTypes.shape({
    required: PropTypes.bool,
  }),
  colors: PropTypes.shape({
    grayDark: PropTypes.string,
    text: PropTypes.string,
    background: PropTypes.string,
    primary: PropTypes.string,
    secondary: PropTypes.string,
    muted: PropTypes.string,
    success: PropTypes.string,
    info: PropTypes.string,
    warning: PropTypes.string,
    danger: PropTypes.string,
    light: PropTypes.string,
    dark: PropTypes.string,
    textMuted: PropTypes.string,
  }),
  theme: PropTypes.oneOf(["base", "dark", "bootstrap", "system"]),
};

RegFormModal.defaultProps = {
  registerVia: "emailOrMobile",
  buttonLabel: "Register",
  langauage: "en",
  heading: "",
  validateEmail: () => {},
  validateContactNumber: () => {},
  theme: "base",
  Trigger: Button,
};

export default React.memo(RegFormModal);
