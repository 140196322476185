/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { Box, Text, Button, Flex, Input, Label, ThemeProvider } from "theme-ui";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Icon from "sites-common/components/ui/Icon";
import Checkbox from "../Checkbox";
import RegisterViaRadio from "./RegisterViaRadio";
import ConditionalInputField from "./ConditionalInputField";
import MissedCallBlock from "./MissedCallBlock";
import FormMessages from "./FormMessages";
import Heading from "./Heading";
import Tos from "../../Tos";
import { PresetThemes } from "../../../shared";
import FileInput from "../FileInput";

const EMAIL_REGEX = /\S+@\S+\.\S+/;

// TODO - update close button styles
// TODO - integrate with modal
const RegFormLayout = ({
  conditionalInputField,
  regViaRadio,
  formMessages,
  missedCallBlock,
  onSubmit,
  userNameTextField,
  heading,
  termsAndConditionsCheckbox,
  buttonLabel,
  onClose,
  themeProvider,
  fileInput,
}) => (
  <ThemeProvider {...themeProvider}>
    <form onSubmit={onSubmit}>
      <Box
        sx={{
          bg: "primary",
          flexDirection: "column",
          borderColor: "background",
          borderWidth: "thin",
          borderStyle: "solid",
          fontFamily: "body",
        }}
      >
        <Flex sx={{ justifyContent: "flex-end", margin: "1px" }}>
          <Button variant="iconButton" type="button" onClick={onClose}>
            <Icon icon="times" />
          </Button>
        </Flex>
        <Box sx={{ padding: "0 20px" }}>
          <MissedCallBlock {...missedCallBlock} />
          <Heading {...heading} />
          <RegisterViaRadio {...regViaRadio} />
          <Input
            variant="primary"
            name="userFullName"
            sx={{
              margin: "20px 0",
            }}
            {...userNameTextField}
          />
          <ConditionalInputField {...conditionalInputField} />
          <FileInput {...fileInput} />
          <Box sx={{ minHeight: "42px", margin: "15px 0" }}>
            <FormMessages {...formMessages} />
          </Box>
          <Box
            sx={{
              margin: "10px 0 30px",
            }}
          >
            <Label>
              <Checkbox
                variant="boxes.primaryCheckbox"
                name="termsAndConditions"
                {...termsAndConditionsCheckbox}
              />
              <Text variant="inverted">
                <Tos sx={{ ml: 1 }} />
              </Text>
            </Label>
          </Box>
          <Box
            sx={{
              margin: "10px 0 30px",
            }}
          >
            <Button type="submit" variant="variant4">
              {buttonLabel}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  </ThemeProvider>
);

RegFormLayout.propTypes = {
  heading: PropTypes.shape(PropTypes.any),
  conditionalInputField: PropTypes.shape({}),
  regViaRadio: PropTypes.shape({}),
  formMessages: PropTypes.shape({}),
  missedCallBlock: PropTypes.shape({
    teleNumber: PropTypes.string,
    show: PropTypes.bool,
    text: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  userNameTextField: PropTypes.shape({}),
  termsAndConditionsCheckbox: PropTypes.shape({}),
  buttonLabel: PropTypes.shape(PropTypes.any),
  themeProvider: PropTypes.shape({}),
  fileInput: PropTypes.shape({}),
};

RegFormLayout.defaultProps = {
  heading: "",
  conditionalInputField: {},
  regViaRadio: {},
  formMessages: {},
  missedCallBlock: {},
  onSubmit: () => {},
  onClose: () => {},
  userNameTextField: {},
  termsAndConditionsCheckbox: {},
  buttonLabel: "",
  themeProvider: {},
  fileInput: {},
};

const RegForm = ({
  // properties
  registerVia,
  missedCallDetails,
  heading,
  language,
  buttonLabel,
  colors,
  theme,
  fileInput,
  // methods
  validateEmail,
  validateContactNumber,
  onSubmit,
  onClose,
}) => {
  const { i18n, t } = useTranslation();

  const { handleSubmit, register, errors, formState, setValue } = useForm();

  const [state, setState] = React.useState({
    conditionalInputField: {
      show: false,
      showEmailInput: false,
      showPhoneNumberInput: false,
    },
    formMessages: {
      show: false,
    },
  });

  const updateConditionalInputField = React.useCallback((update = {}) => {
    setState((currentState) => ({
      ...currentState,
      conditionalInputField: {
        ...currentState.conditionalInputField,
        ...update,
      },
    }));
  }, []);

  const updateFormMessages = React.useCallback((update) => {
    setState((currentState) => ({
      ...currentState,
      formMessages: {
        ...currentState.formMessages,
        ...update,
      },
    }));
  }, []);

  const regFormLayout = {
    onClose,
    fileInput: React.useMemo(() => {
      if (!fileInput)
        return {
          show: false,
        };
      return {
        show: true,
        validationRef: register({ required: fileInput.required }),
        name: "file",
        ...fileInput,
      };
    }, [fileInput, register]),
    themeProvider: {
      theme: {
        ...PresetThemes[theme],
        colors: {
          ...PresetThemes[theme].colors,
          ...colors,
        },
      },
    },
    heading: {
      show: React.useMemo(() => Boolean(heading), [heading]),
      heading,
    },
    userNameTextField: {
      ref: React.useMemo(
        () =>
          register({
            validate: {
              isNotBlank: (value) =>
                !!value.trim() || t("Please enter your name."),
            },
          }),
        [register, t]
      ),
      placeholder: t("Name"),
    },
    onSubmit: handleSubmit(async (data) => {
      try {
        const successMessage = await onSubmit(data);
        updateFormMessages({
          variant: "success",
          message: successMessage,
          show: true,
        });
      } catch (e) {
        updateFormMessages({
          variant: "danger",
          message: e.message,
          show: true,
        });
      }
    }),
    regViaRadio: {
      show: React.useMemo(() => {
        switch (registerVia) {
          case "emailOrMobile":
            return true;
          default:
            return false;
        }
      }, [registerVia]),
      onChange: React.useCallback(
        (payload) => {
          if (registerVia === "emailOrMobile") {
            updateConditionalInputField({
              showEmailInput: payload === "email",
              showPhoneNumberInput: payload === "mobile",
            });
          }
        },
        [updateConditionalInputField, registerVia]
      ),
      ref: register,
    },
    conditionalInputField: {
      ...state.conditionalInputField,
      emailRef: React.useMemo(
        () =>
          register({
            validate: {
              isNotBlank: (value) =>
                !!value.trim() || t("Please enter your email."),
              isValid: (value) =>
                EMAIL_REGEX.test(value) || t("Email is not valid."),
              validateEmail,
            },
          }),
        [register, validateEmail, t]
      ),
      phoneNumberRef: React.useMemo(
        () =>
          register({
            validate: {
              isNotBlank: (value) =>
                !!value.trim() || t("Please enter your contact number."),
              validateContactNumber,
            },
          }),
        [register, t, validateContactNumber]
      ),
      onChangePhoneNumber: React.useCallback(
        (...args) => {
          const [, , , contactNumber] = args;
          setValue("contactNumber", contactNumber);
        },
        [setValue]
      ),
    },
    formMessages: {
      ...state.formMessages,
    },
    missedCallBlock: {
      show: React.useMemo(() => {
        try {
          return Boolean(
            missedCallDetails.teleNumber && missedCallDetails.text
          );
        } catch (e) {
          return false;
        }
      }, [missedCallDetails]),
      ...missedCallDetails,
    },
    termsAndConditionsCheckbox: {
      ref: register({
        validate: {
          isChecked: (value) =>
            !!value || t("Please check the terms & conditions."),
        },
      }),
    },
    buttonLabel: t(buttonLabel),
  };

  React.useEffect(() => {
    switch (registerVia) {
      case "emailOrMobile":
        updateConditionalInputField({
          showEmailInput: true,
          showPhoneNumberInput: false,
        });
        break;
      case "emailOnly":
        updateConditionalInputField({
          showEmailInput: true,
          showPhoneNumberInput: false,
        });
        break;
      case "mobileOnly":
        updateConditionalInputField({
          showEmailInput: false,
          showPhoneNumberInput: true,
        });
        break;
      case "both":
        updateConditionalInputField({
          showEmailInput: true,
          showPhoneNumberInput: true,
        });
        break;
      default:
        updateConditionalInputField({
          showEmailInput: false,
          showPhoneNumberInput: false,
        });
    }
  }, [registerVia, updateConditionalInputField]);

  React.useEffect(() => {
    if (errors.userFullName) {
      updateFormMessages({
        variant: "danger",
        message: errors.userFullName.message,
        show: true,
      });
    } else if (errors.email) {
      updateFormMessages({
        variant: "danger",
        message: errors.email.message,
        show: true,
      });
    } else if (errors.contactNumber) {
      updateFormMessages({
        variant: "danger",
        message: errors.contactNumber.message,
        show: true,
      });
    } else if (errors.termsAndConditions) {
      updateFormMessages({
        variant: "danger",
        message: errors.termsAndConditions.message,
        show: true,
      });
    }
  }, [errors, updateFormMessages]);

  React.useEffect(() => {
    if (formState.isSubmitting) {
      updateFormMessages({
        variant: "inverted",
        message: "Loading...Please wait..",
        show: true,
      });
    }
  }, [formState.isSubmitting, updateFormMessages]);

  React.useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return <RegFormLayout {...regFormLayout} />;
};

RegForm.propTypes = {
  registerVia: PropTypes.oneOf([
    "emailOnly",
    "mobileOnly",
    "emailOrMobile",
    "both",
  ]),
  buttonLabel: PropTypes.shape(PropTypes.any),
  language: PropTypes.oneOf(["en", "fr", "hi"]),
  heading: PropTypes.shape(PropTypes.any),
  missedCallDetails: PropTypes.shape({
    teleNumber: PropTypes.string,
    displayNumber: PropTypes.string,
    text: PropTypes.string,
  }),
  validateEmail: PropTypes.func,
  validateContactNumber: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  colors: PropTypes.shape({
    grayDark: PropTypes.string,
    text: PropTypes.string,
    background: PropTypes.string,
    primary: PropTypes.string,
    secondary: PropTypes.string,
    muted: PropTypes.string,
    success: PropTypes.string,
    info: PropTypes.string,
    warning: PropTypes.string,
    danger: PropTypes.string,
    light: PropTypes.string,
    dark: PropTypes.string,
    textMuted: PropTypes.string,
  }),
  theme: PropTypes.oneOf(["base", "dark", "bootstrap", "system"]),
  fileInput: PropTypes.shape({
    required: PropTypes.bool,
  }),
};

RegForm.defaultProps = {
  language: "en",
  buttonLabel: "Register",
  validateEmail: () => {},
  validateContactNumber: () => {},
  theme: "bootstrap",
  colors: {},
  registerVia: "emailOrMobile",
  heading: "",
  missedCallDetails: {},
  fileInput: {},
};

export default React.memo(RegForm);
