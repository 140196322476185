import React from "react";
import PropTypes from "prop-types";
import { Checkbox as ThemeUICheckbox, Box } from "theme-ui";

/**
A customized checkbox with inverted colors
*/
const Checkbox = React.forwardRef(({ variant, ...props }, ref) => (
  <Box variant={variant}>
    <ThemeUICheckbox {...props} ref={ref} />
  </Box>
));

Checkbox.propTypes = {
  /** The variant for the Checkbox */
  variant: PropTypes.oneOf(["boxes.primaryCheckbox"]),
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  variant: "boxes.primaryCheckbox",
};

export default Checkbox;
