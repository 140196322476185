/** @jsx jsx */
import { jsx, Flex, Box, Container /* Text */ } from "theme-ui";
import { useState, useEffect } from "react";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { doFormatShortDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
// import { navigate } from "@reach/router";
import { Pivot, PivotItem } from "office-ui-fabric-react";
// import { buttonStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { get } from "lodash";
import DashboardPendingApprovals from "../../components/DashboardPendingApprovals";
import DownloadEventReg from "../../components/DashboardDownloadEvent";
import DashboardMail from "../../components/DashboardMail";
import DetailCard from "../../components/DetailCard";
import RegistrationWidget from "../../components/RegistrationWidget";
import Attachments from "../../components/Attachments";
import Attendance from "../../components/Attendance";
import DashboardRegistrants from "../../components/DashboardRegistrants";
import PaidSku from "../../components/PaidSku";

const Dashboard = ({ eventName }) => {
  const [eventDetail, setEventDetail] = useState();
  const { fetchSrcmApi } = useFetchSrcmApi();
  const xeventInfo = get(eventDetail, "event_json.custom_config_json", "");
  const customConfigJson = xeventInfo ? JSON.parse(xeventInfo) : "";
  const specialAccommodation = get(
    customConfigJson,
    "ej_special_accommodation",
    false
  );
  const downloadFieldSet = get(
    customConfigJson,
    "download_additional_fields",
    {}
  );
  const approvalLevel = get(customConfigJson, "approval_level", "INDIVIDUAL");
  const paidEvent = get(eventDetail, "is_paid_event", false);
  const approvalOnPayment = get(customConfigJson, "approval_on_payment", false);
  // const profile = useAuth();

  // const loggedUser = useMemo(
  //   () => ({
  //     id: profile.srcmProfile.id,
  //   }),
  //   [profile]
  // );

  useEffect(() => {
    fetchSrcmApi({
      api: `/api/v3/events/${eventName}/`,
      client: "eventsClient",
    }).then((data) => {
      setEventDetail(data);
    });
  }, [eventName, fetchSrcmApi]);

  const pivotItemStyle = {
    "& > div": {
      "@media (max-width: 480px)": {
        display: "flex",
        flexDirection: "column",
      },
    },
  };

  // const checkIfOrgMatch = some(
  //   get(eventDetail, "organizers", ""),
  //   (orgList) => {
  //     return orgList.id === loggedUser.id;
  //   }
  // );

  // const isOrganizer =
  //   (!!get(eventDetail, "organizers", "") && checkIfOrgMatch) ||
  //   loggedUser.email === get(eventDetail, "created_by_partner.id", null);

  return (
    <Container>
      <Box sx={{ my: 2 }}>
        <Container
          as="ul"
          sx={{
            listStyle: "none",
            color: "#333333",
            pl: 0,
            mt: 2,
            "& > li+li": {
              "::before": {
                content: "' > '",
              },
            },
          }}
        >
          <li
            sx={{
              display: "inline",
            }}
          >
            <Link
              sx={{ textDecoration: "none", color: "#999999" }}
              to="/my-events"
            >
              My Events
            </Link>
          </li>
          <li sx={{ display: "inline" }}>Registrant Dashboard</li>
        </Container>
        <Flex
          sx={{
            fontSize: "22px",
            py: 3,
            alignItems: "center",
            borderBottom: "1px solid #dddddd",
            "@media (max-width: 480px)": {
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <Box pr={3} sx={{ fontSize: "26px" }}>
            {eventDetail ? eventDetail.title : ""}
          </Box>
          <Box sx={{ color: "#999999", fontSize: "18px" }}>
            {eventDetail ? doFormatShortDate(eventDetail.start_datetime) : ""} -{" "}
            {eventDetail ? doFormatShortDate(eventDetail.end_datetime) : ""}
          </Box>
        </Flex>
      </Box>
      <Pivot sx={pivotItemStyle}>
        <PivotItem headerText="Dashboard">
          <DetailCard eventid={eventName} />
        </PivotItem>
        <PivotItem headerText="Download/Upload">
          <Box>
            <DownloadEventReg
              eventTitle={eventDetail?.title}
              eventid={eventName}
              iconProps={{ iconName: "Download" }}
              fieldSet={downloadFieldSet}
              btnProps={{
                text: "Download Registrations",
                target: "_blank",
                iconProps: { iconName: "Download" },
              }}
            />
          </Box>
        </PivotItem>
        <PivotItem headerText="Registration Search">
          <Box sx={{ m: 2, p: 2 }}>
            <DashboardRegistrants eventid={eventName} />
          </Box>
        </PivotItem>
        <PivotItem headerText="Mail/SMS">
          <Box sx={{ m: 2, p: 2 }}>
            <DashboardMail
              eventid={eventName}
              eventname={get(eventDetail, "title", "")}
            />
          </Box>
        </PivotItem>
        <PivotItem headerText="Pending Approvals">
          <Box sx={{ m: 2, p: 2 }}>
            <DashboardPendingApprovals
              eventid={eventName}
              approvalLevel={approvalLevel}
              paidEvent={paidEvent}
              approvalOnPayment={approvalOnPayment}
            />
          </Box>
        </PivotItem>
        <PivotItem headerText="Event Registration Widget">
          <Box sx={{ m: 2, p: 2 }}>
            <RegistrationWidget eventid={eventName} eventInfo={eventDetail} />
          </Box>
        </PivotItem>
        <PivotItem headerText="Attendance">
          <Box sx={{ m: 2, p: 2 }}>
            <Attendance eventid={eventName} />
          </Box>
        </PivotItem>
        <PivotItem headerText="Attachments">
          <Box sx={{ m: 2, p: 2 }}>
            <Attachments eventid={eventName} />
          </Box>
        </PivotItem>
        {specialAccommodation ? (
          <PivotItem headerText="Paid SKU">
            <Box sx={{ m: 2, p: 2 }}>
              <PaidSku eventid={eventName} />
            </Box>
          </PivotItem>
        ) : (
          ""
        )}
      </Pivot>
    </Container>
  );
};

Dashboard.propTypes = {
  eventName: PropTypes.string.isRequired,
};

export default Dashboard;
