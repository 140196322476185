import { Flex, Label } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";
import ReactDayPickerInput from "react-day-picker/DayPickerInput";
import InputWithIcon from "sites-common/components/ui/InputWithIcon";

const DayPickerInput = React.forwardRef((props, ref) => {
  const { label = "", inputProps = {}, value, ...restProps } = props;

  const inputRef1 = React.useRef(null);
  const inputRef = ref || inputRef1;
  const [state, setState] = React.useState({
    showLabel: false,
  });

  const onFocus = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showLabel: true,
    }));
  }, []);

  const onBlur = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showLabel: prevState.showLabel,
    }));
  }, []);

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      showLabel: !!value,
    }));
  }, [value]);

  return (
    <Flex
      sx={{ flexDirection: "column" }}
      onFocus={onFocus}
      onBlur={onBlur}
      variant="flex.daypickerInput"
    >
      <Label
        sx={{
          paddingLeft: "6px",
          height: "1rem",
          fontSize: "0.8rem",
          fontWeight: "300",
          fontFamily: "body",
        }}
      >
        {state.showLabel && label}
      </Label>
      <ReactDayPickerInput
        value={value}
        inputProps={{
          placeholder: state.showLabel ? "" : label,
          ref: inputRef,
          ...inputProps,
        }}
        component={InputWithIcon}
        {...restProps}
      />
    </Flex>
  );
});

DayPickerInput.defaultProps = {
  label: "",
  inputProps: {},
  value: "",
};

DayPickerInput.propTypes = {
  label: PropTypes.string,
  inputProps: PropTypes.shape({
    variant: PropTypes.oneOf(["border-bottom", "border-0", "primary"]),
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

export default DayPickerInput;
