/** @jsx jsx */
import { useState, useEffect, useCallback } from "react";
import { jsx, Box, Container } from "theme-ui";
import { number, PropTypes } from "prop-types";
import useFetchOmsApi from "gatsby-plugin-hfn-profile/components/Hooks/useFetchOmsApi";
import { DetailsList, SelectionMode } from "office-ui-fabric-react";

const PaidSku = ({ eventid }) => {
  const [paidData, setPaidData] = useState([]);
  const [eventName] = useState(eventid);
  const { fetchOmsApi } = useFetchOmsApi();

  const paidInitColumns = [
    {
      key: "id",
      fieldName: "id",
      name: "S.No",
      data: number,
      isResizable: true,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      key: "title",
      fieldName: "title",
      name: "Dorm",
      data: "string",
      isResizable: true,
      minWidth: 400,
      maxWidth: 400,
    },
    {
      key: "inventory",
      fieldName: "inventory",
      name: "Inventory",
      data: number,
      isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "bookedInventory",
      fieldName: "bookedInventory",
      name: "Booked Inventory",
      data: number,
      isResizable: true,
      minWidth: 100,
      maxWidth: 150,
    },
    {
      key: "totalAvailableInventoryCount",
      fieldName: "totalAvailableInventoryCount",
      name: "Remaining",
      data: number,
      isResizable: true,
      maxWidth: 200,
    },
    {
      key: "reservation",
      fieldName: "reservation",
      name: "Temporary blocked Inventory",
      data: number,
      isResizable: true,
      maxWidth: 200,
    },
  ];

  const getPaidAccomodation = useCallback(() => {
    fetchOmsApi({
      api: `/sku-group/${eventName}`,
    }).then((data) => {
      setPaidData(data);
    });
  }, [fetchOmsApi, eventName]);

  useEffect(() => {
    getPaidAccomodation();
  }, [getPaidAccomodation]);

  const renderPaidItemColumn = useCallback((item, index, column) => {
    const fieldContent =
      column && column.fieldName ? item[column.fieldName] : "";
    switch (column.key) {
      case "id":
      case "title":
        return fieldContent || "-";
      case "inventory":
      case "bookedInventory":
      case "totalAvailableInventoryCount":
        return fieldContent || 0;
      case "reservation": {
        const inventory = item.inventory ? item.inventory : 0;
        const bookedInventory = item.bookedInventory ? item.bookedInventory : 0;
        const totalAvailableInventoryCount = item.totalAvailableInventoryCount
          ? item.totalAvailableInventoryCount
          : 0;
        return (
          inventory - (bookedInventory + totalAvailableInventoryCount) || 0
        );
      }
      default:
        return index;
    }
  }, []);

  return (
    <Container>
      <Box>
        {paidData && paidData.sku && paidData.sku.length ? (
          <DetailsList
            columns={paidInitColumns}
            items={paidData.sku}
            setKey="set"
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderPaidItemColumn}
            compact
            enableUpdateAnimations
          />
        ) : (
          <Box sx={{ textAlign: "center", p: 3 }}>
            <h3>No records found.</h3>
          </Box>
        )}
      </Box>
    </Container>
  );
};

PaidSku.propTypes = {
  eventid: PropTypes.string.isRequired,
};

export default PaidSku;
