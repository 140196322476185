/** @jsx jsx */
import {
  jsx,
  Box,
  Flex,
  Text,
  Container,
  Card,
  Button,
  Spinner,
  Grid,
} from "theme-ui";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { get, lowerCase, set, isEmpty } from "lodash";
// import generatePNR from "sites-common/utils/generatePNR";
import { DefaultButton, PivotItem, Pivot } from "office-ui-fabric-react";
import { validateEmail } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import { useFirebase } from "react-redux-firebase";
import moment from "moment";
import CountryCodes from "../utils/countryCodes";
import BulkUpload from "./BulkUpload";
import { timeOptions } from "../../../kanha-shantivanam/src/assets/data/bandaraRegistration";

const defaultDoFilter = {
  status: "-select-",
  gender: "-select-",
  stay_preference: "-select-",
  city_id: "",
};

const DownloadEventReg = React.memo(({ eventid, btnProps, fieldSet }) => {
  const { fetchSrcmApi } = useFetchSrcmApi();
  const [fkey, setFKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [csvHeader, setCsvHeader] = useState([]);
  // const [stayPreferance, setStayPreferanceKey] = useState(null);
  // const [filteredSkuRegistered, setFilteredSkuRegistered] = useState({
  //   loading: true,
  //   f: [],
  // });

  const [doFilter, doSetFilter] = useState({ ...defaultDoFilter });
  const [skuOptions, setSkuOptions] = useState({
    loading: true,
    s: [{ name: "loading", label: "...Loading" }],
  });
  const [, setRegistrations] = useState([]);
  const [downloadCsvData, setDownloadCsvData] = useState([]);
  const [filterCount, setFilterCount] = useState({
    count: null,
    loading: false,
  });

  useEffect(() => {
    const downloadCsvHeaders = [
      { label: "Name", key: "name" },
      { label: "Email", key: "email" },
      { label: "Gender", key: "gender" },
      { label: "Age", key: "age_group" },
      { label: "Mobile", key: "mobile" },
      { label: "City", key: "city_name" },
      { label: "Abhyasi ID", key: "ref" },
      { label: "PNR No", key: "pnr" },
      { label: "Status", key: "status" },
      { label: "DOJ", key: "date_of_joining" },
      { label: "Stay Preferance in ashram", key: "stay_preference" },
    ];
    setCsvHeader([...downloadCsvHeaders, ...fieldSet]);
  }, [fieldSet]);

  const firebase = useFirebase();
  const shopventory = useRef(null);

  useEffect(() => {
    if (!shopventory.current) {
      shopventory.current = firebase.functions().httpsCallable("shopventory");
    }
  }, [firebase]);

  useEffect(() => {
    if (skuOptions.loading) {
      shopventory
        .current({
          request: "get-admin-shopventory",
          payload: { stock_group: eventid },
        })
        .then((skr) => {
          const skuOptionList = skr.data.skus.map(({ sku, desc }) => {
            return { name: sku, label: desc };
          });
          setSkuOptions({ loading: false, s: skuOptionList });
        });
    }
  }, [eventid, skuOptions.loading]);

  useEffect(() => {
    function getRegistrations() {
      fetchSrcmApi({
        api: `/api/v3/events/${eventid}/registrations/`,
        client: "eventsClient",
      }).then((data) => {
        setRegistrations(data.results);
      });
    }
    getRegistrations();
  }, [fetchSrcmApi, eventid]);

  const generateCsvJson = (data) => {
    let csvJSon = [];
    let regJson = {};
    csvJSon = data.map((item) => {
      if (typeof item?.reg_json === "string")
        regJson = JSON.parse(item?.reg_json);
      const staticData = {
        name: item.name,
        email: item.email,
        gender: item.gender,
        age_group: item.age_group,
        mobile: item.mobile,
        city_name: item.city_name,
        ref: item.ref === "B99999999" ? "" : item.ref,
        pnr: item.pnr,
        status: item.status,
        date_of_joining: item.date_of_joining
          ? moment(item.date_of_joining).format("MMM DD, YYYY")
          : null,
        stay_preference: item.stay_preference,
      };
      const dynamicData = {};
      fieldSet.map((k) => {
        dynamicData[k?.key] = regJson[k?.key];
        return true;
      });
      const downloadData = { ...staticData, ...dynamicData };
      return downloadData;
    });
    return csvJSon;
  };

  const filterApi = useCallback(
    (filterOption) =>
      new Promise((resolve) => {
        fetchSrcmApi({
          api: `/api/v3/events/${eventid}/registrations/`,
          methodParams: {
            ...filterOption,
            event_name: eventid,
            page_size: 200,
          },
          client: "eventsClient",
        })
          .then((result) => {
            resolve(result);
          })
          .catch(() => {
            return null;
          });
      }),
    [eventid, fetchSrcmApi]
  );

  const setKey = (v) => {
    setIsLoading(true);
    setFKey(v);

    filterApi({ status: v }).then((re) => {
      setDownloadCsvData(generateCsvJson(re.results));
      setIsLoading(false);
    });
  };

  // const setStayPref = useCallback(
  //   (v) => {
  //     setFilteredSkuRegistered({ loading: true, f: [] });

  //     if (v === "loading") {
  //       return;
  //     }
  //     const filteredSkuData = registrations.filter(
  //       (i) => i.stay_preference === v && i.status === "confirmed"
  //     );

  //     setFilteredSkuRegistered({ loading: false, f: filteredSkuData });
  //     setStayPreferanceKey(v);
  //   },
  //   [registrations]
  // );

  // const fileUpload = useRef();
  // const checkExtension = () => {
  //   const filePath = fileUpload.current.value;
  //   const allowedExtensions = /(\.csv)$/i;

  //   if (!allowedExtensions.exec(filePath)) {
  //     return false;
  //   }
  //   return true;
  // };

  const countryOptions = {
    countries: CountryCodes.map((r) => ({
      name: r.code,
      label: r.name,
    })),
  };

  const onSubmit = (data, setState, conditionalMessageBar) => {
    const body = [];
    const regPnr = "NEWPNR";
    // generatePNR(data[0].email || data[0].name);
    data.forEach((element) => {
      const {
        event,
        name,
        email,
        mobile,
        city_id,
        reg_json,
        communication_preference,
        has_registered,
        gender,
        age_group,
        status,
        arrival_date,
        arrival_time,
        departure_date,
        departure_time,
        stay_preference,
        ...rest
      } = element;
      const dataBody = {
        ref: "B99999999",
        event,
        name,
        email,
        mobile,
        city_id,
        communication_preference,
        has_registered,
        pnr: regPnr,
        gender,
        age_group,
        arrival_date: moment(
          new Date(
            `${moment(arrival_date, "DD_MM_YYYY").format(
              "YYYY-MM-DD"
            )} ${arrival_time}`
          )
        ).toISOString(),
        departure_date: moment(
          new Date(
            `${moment(departure_date, "DD_MM_YYYY").format(
              "YYYY-MM-DD"
            )} ${departure_time}`
          )
        ).toISOString(),
        stay_preference,
        reg_json: { ...rest },
      };
      body.push(dataBody);
    });

    fetchSrcmApi({
      api: `/api/v3/events/${eventid}/registrations/`,
      method: "POST",
      methodParams: body,
      client: "eventsClient",
    })
      .then(() => {
        setState((s) => ({ ...s, phase: "uploaded" }));
      })
      .catch(() => {
        conditionalMessageBar.setState((s) => ({
          ...s,
          type: "error",
          txt: "Data has some error. Please try again.",
          show: true,
        }));
        setState((s) => ({ ...s, phase: "data-has-errors" }));
      });
  };

  const getCountryCode = (country) => {
    const dialCode = CountryCodes.filter(
      (cobj) => lowerCase(cobj.code) === lowerCase(country)
    ).map((cobj) => cobj.dial_code);

    if (Array.isArray(dialCode) && isEmpty(dialCode)) {
      return "+91";
    }
    return dialCode;
  };

  const validateMobile = (mobile, country) => {
    const mobileNo = mobile.toString();
    const dialCode = getCountryCode(country);
    return `${dialCode}${mobileNo}`;
  };

  const postProcess = (newd, olddata = {}) => {
    const values = { ...olddata, ...newd };
    if ("mobile" in values) {
      const country = get(values, ["country"]);
      const mobile = get(values, ["mobile"], []);
      const mobileNo = validateMobile(mobile, country);
      set(values, "mobile", `${mobileNo}`);
    }

    return values;
  };

  const formValidator = (r) => {
    const f_email = get(r, ["email"]);

    if (!validateEmail(f_email)) {
      return "Invalid email address.";
    }

    return null;
  };

  const downlodIcon = { iconName: "Download" };

  const applyMultiFilter = useCallback(() => {
    setFilterCount({ ...filterCount, loading: true });
    let selectedFilters = { ...doFilter };

    Object.keys(selectedFilters).forEach((key) => {
      if (selectedFilters[key] === "-select-") {
        selectedFilters = { ...selectedFilters, [key]: "" };
      }
    });
    filterApi(selectedFilters)
      .then((re) => {
        if (re) {
          setFilterCount({ loading: false, count: re?.count });
        }
      })
      .catch(() => {
        setFilterCount({ loading: false, count: "Try again later" });
      });
  }, [doFilter, filterCount, filterApi]);

  return (
    <Container py={3} px={2}>
      <Pivot>
        <PivotItem headerText="Download">
          <Box
            sx={{
              p: 2,
              maxWidth: 500,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "3",
                p: 2,
              }}
            >
              <Box>
                <Text>Download Participants:</Text>
                <DynamicField
                  name="fkey"
                  label="Select filter by status"
                  value={fkey}
                  onChange={setKey}
                  type="select"
                  options={[
                    // { name: "all", label: "All" },
                    { name: "confirmed", label: "Confirmed" },
                    { name: "cancelled", label: "Cancelled" },
                    { name: "pending", label: "Pending" },
                    { name: "not_approved", label: "Not Approved" },
                  ]}
                />
                <Flex sx={{ gap: 3, pt: 3, pb: 3 }}>
                  <CSVLink
                    sx={{ pointerEvents: !fkey && "none" }}
                    headers={csvHeader}
                    data={downloadCsvData}
                    filename="event-registrations.csv"
                  >
                    {!isLoading && (
                      <DefaultButton
                        styles={buttonStyles.blueLight}
                        disabled={!fkey}
                        {...btnProps}
                      />
                    )}
                    {isLoading && (
                      <DefaultButton
                        styles={buttonStyles.blueLight}
                        disabled="true"
                        text="Loading...."
                        iconProps={downlodIcon}
                      />
                    )}
                  </CSVLink>
                </Flex>
              </Box>
              <Box style={{ display: "none" }}>
                <Flex>
                  <Box>
                    <Card>
                      <Text>Filter:</Text>
                      <DynamicField
                        name="status"
                        label="Status"
                        props={{ required: false }}
                        value={doFilter.status}
                        onChange={(e) =>
                          doSetFilter({ ...doFilter, status: e })
                        }
                        type="select"
                        options={[
                          {
                            name: "-select-",
                            label: "-select-",
                            value: "-select-",
                          },
                          {
                            name: "confirmed",
                            label: "Confirmed",
                            value: "confirmed",
                          },
                          {
                            name: "cancelled",
                            label: "Cancelled",
                            value: "cancelled",
                          },
                        ]}
                      />
                      <DynamicField
                        name="gender"
                        label="Gender"
                        value={doFilter.gender}
                        props={{ required: false }}
                        onChange={(e) =>
                          doSetFilter({ ...doFilter, gender: e })
                        }
                        type="select"
                        options={[
                          {
                            name: "-select-",
                            label: "-select-",
                            value: "-select-",
                          },
                          { name: "M", label: "Male", value: "M" },
                          { name: "F", label: "Female", value: "F" },
                          { name: "U", label: "Unspecified", value: "U" },
                        ]}
                      />
                      {/* <DynamicField
                        name="city_id"
                        label="City / State / Country"
                        type="city"
                        value={doFilter.city_id}
                        onChange={(e) =>
                          doSetFilter({ ...doFilter, city_id: e })
                        }
                        props={{
                          required: false,
                          data_keys: {
                            city: ["city_id", ""],
                          },
                        }}
                      /> */}
                      <DynamicField
                        name="stay_preference"
                        label="Stay Preferance in Ashram "
                        value={doFilter.stay_preference}
                        props={{ required: false }}
                        onChange={(e) =>
                          doSetFilter({ ...doFilter, stay_preference: e })
                        }
                        type="select"
                        options={[
                          {
                            name: "-select-",
                            label: "-select-",
                            value: "-select-",
                          },
                          { name: "YES", label: "Yes", value: "YES" },
                          { name: "NO", label: "No", value: "NO" },
                        ]}
                      />
                      <Grid columns={2}>
                        <Box>
                          <Button
                            sx={{
                              cursor: "pointer",
                              mt: 3,
                              mb: 3,
                              fontSize: "13px",
                            }}
                            variant="primary"
                            onClick={() => applyMultiFilter()}
                          >
                            Apply Filter
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            sx={{
                              cursor: "pointer",
                              mt: 3,
                              mb: 3,
                              fontSize: "13px",
                            }}
                            variant="primary"
                            onClick={() => {
                              doSetFilter({ ...defaultDoFilter });
                              setFilterCount({ ...filterCount, count: 0 });
                            }}
                          >
                            Clear Filter
                          </Button>
                        </Box>
                      </Grid>
                      <Box>
                        Count:{" "}
                        {filterCount.loading ? (
                          <Spinner size={10} />
                        ) : (
                          filterCount.count
                        )}
                      </Box>
                    </Card>
                  </Box>
                </Flex>
              </Box>
              {/* {includes(eventTitle.toLowerCase(), "bandara") && (
                  <Box>
                    <DynamicField
                      name="stayPreferance"
                      label="Select filter by stay preferance"
                      value={stayPreferance}
                      onChange={setStayPref}
                      type="select"
                      options={skuOptions.s}
                    />
                    {stayPreferance && (
                      <Flex sx={{ gap: 3, pb: 3 }}>
                        {filteredSkuRegistered.loading ? (
                          <Spinner size={20} />
                        ) : (
                          <h5>
                            Total Registrants: {filteredSkuRegistered.f.length}
                          </h5>
                        )}
                      </Flex>
                    )}
                  </Box>
                )} */}
            </Box>
          </Box>
        </PivotItem>
        <PivotItem headerText="Upload">
          <Box
            sx={{
              p: 2,
            }}
          >
            <Box sx={{ p: 2 }}>
              <Text>Upload Participants:</Text>
              <br />
              <Box>
                <Box p={1}>
                  <Text sx={{ fontSize: "14px" }}>
                    1. Download sample template.
                  </Text>
                  <br />
                </Box>
                <Box p={1}>
                  <DefaultButton
                    styles={buttonStyles.blueLight}
                    iconProps={{ iconName: "Download" }}
                  >
                    <CSVLink
                      data={[
                        {
                          name: "samplename",
                          email: "sample@mail.com",
                          mobile: "8888888888 (without +country code)",
                          country:
                            "Enter the country code using ISO ALPHA-2 format. Eg:(IN/US/JP)",
                          arrival_date:
                            "DD_MM_YYYY (Note: Arrival date should be ahead of current Date)",
                          arrival_time: "HH:MM:SS Eg:(09:00:00)",
                          departure_date:
                            "DD_MM_YYYY (Note: Departure date should be ahead of arrival Date, You are only allowed to stay for one week.)",
                          departure_time: "HH:MM:SS Eg:(20:00:00)",
                          stay_preference: "YES / NO",
                          gender: "M/F",
                        },
                      ]}
                      filename="participants-upload-template.csv"
                      sx={{ textDecoration: "none", color: "#fff " }}
                    >
                      Download
                    </CSVLink>
                  </DefaultButton>
                </Box>
              </Box>
              <Box>
                <Box p={1}>
                  <Text sx={{ fontSize: "14px" }}>
                    2. Select filled participants excel & upload below,
                    (supported format .csv)
                  </Text>
                  <br />
                </Box>
                <Box sx={{ fontSize: "14px", backgroundColor: "#fff", p: 3 }}>
                  <BulkUpload
                    config={{
                      postprocess: postProcess,
                      formValidator,
                      formFields: [
                        { name: "name", label: "Full Name" },
                        { name: "email", label: "Email", type: "email" },
                        {
                          name: "mobile",
                          label: "Mobile",
                          type: "phone",
                          // props: {
                          //   required: false
                          // }
                        },
                        {
                          name: "country",
                          label: "Country",
                          type: "autosuggest",
                          options: [...countryOptions.countries],
                          props: {
                            required: false,
                          },
                        },
                        {
                          name: "arrival_date",
                          label: "Arrival Date",
                          type: "date",
                          props: {
                            style: {
                              alignItems: "center",
                              placeItems: "center",
                            },
                          },
                          dynamic: (r) => {
                            if (r.online_registration) {
                              set(
                                r,
                                "arrival_date",
                                moment(r.arrival_date).format("YYYY-MM-DD")
                              );
                            }
                          },
                        },
                        {
                          name: "arrival_time",
                          label: "Arrival Time",
                          type: "select",
                          options: timeOptions,
                          props: {
                            style: {
                              border: "1px solid #eeeeee",
                            },
                          },
                        },
                        {
                          name: "departure_date",
                          label: "Depature Date",
                          type: "date",
                          props: {
                            style: {
                              alignItems: "center",
                              placeItems: "center",
                            },
                          },
                        },
                        {
                          name: "departure_time",
                          label: "Depature Time",
                          type: "select",
                          options: timeOptions,
                          props: {
                            style: {
                              border: "1px solid #eeeeee",
                            },
                          },
                        },
                        {
                          name: "stay_preference",
                          label: "Stay Preferance",
                          type: "select",
                          options: [
                            { name: "YES", label: "Yes", value: "YES" },
                            { name: "NO", label: "No", value: "NO" },
                          ],
                          props: {
                            style: {
                              border: "1px solid #eeeeee",
                            },
                          },
                        },
                      ],
                      formDefaults: {
                        event: eventid,
                        city_id: null,
                        reg_json: {},
                        communication_preference: 1,
                        has_registered: true,
                        gender: null,
                        age_group: null,
                        arrival_date: "",
                        arrival_time: "",
                        departure_date: "",
                        departure_time: "",
                        stay_preference: null,
                      },
                      primaryKey: "id",
                    }}
                    onSubmit={onSubmit}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </PivotItem>
      </Pivot>
    </Container>
  );
});

DownloadEventReg.defaultProps = {
  btnProps: {},
  fieldSet: {},
  city_options: [],
  countries_options: [],
  indiastate_options: [],
  zone_options: null,
  key: null,
  value: null,
  showFilters: true,
};

DownloadEventReg.propTypes = {
  eventid: PropTypes.string.isRequired,
  btnProps: PropTypes.objectOf(PropTypes.any),
  fieldSet: PropTypes.objectOf(PropTypes.any),
  city_options: PropTypes.arrayOf(PropTypes.object),
  countries_options: PropTypes.arrayOf(PropTypes.object),
  indiastate_options: PropTypes.arrayOf(PropTypes.object),
  zone_options: PropTypes.objectOf(PropTypes.any),
  key: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.objectOf(PropTypes.any),
  showFilters: PropTypes.bool,
};

export default DownloadEventReg;
