export const registrantsExtraData = (data, key) => {
  let extraData = {};
  if (typeof data === "string") extraData = JSON.parse(data);
  return extraData?.[key] || "";
};

export const genderValue = (genderList, val) => {
  const res = genderList.find(({ value }) => value === val);
  return res?.label || "";
};

export const getOrderByStatus = (data, val) => {
  return data.find(({ status }) => status === val) || null;
};
