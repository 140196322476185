/** @jsx jsx */
import { jsx, Box, Flex, Container } from "theme-ui";
import { useState, useEffect } from "react";
import {
  DetailsList,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import PropTypes from "prop-types";
import FileSaver from "file-saver";
import { IconButton } from "office-ui-fabric-react";

const Attachments = ({ eventid }) => {
  const [eventName] = useState(eventid);
  const [attachments, setAttachments] = useState([]);
  const { fetchSrcmApi } = useFetchSrcmApi();

  const initialColumns = [
    {
      key: "SNo",
      fieldName: "SNo",
      name: "SNo",
      data: "string",
      isResizable: true,
      minWidth: 50,
      maxWidth: 50,
    },
    {
      key: "filename",
      fieldName: "filename",
      name: "File Name",
      data: "string",
      isResizable: true,
      minWidth: 500,
      maxWidth: 500,
    },
    {
      key: "description",
      fieldName: "description",
      name: "Description",
      data: "string",
      isResizable: true,
      minWidth: 400,
    },
    {
      key: "action",
      fieldName: "action",
      name: "Action",
      data: "string",
      isResizable: true,
      minWidth: 60,
      maxWidth: 100,
      onRender: (item) => (
        <Flex sx={{ gap: 2 }}>
          <IconButton
            iconProps={{ iconName: "Download" }}
            onClick={() => {
              const apiUrl = item.document;
              if (apiUrl) {
                fetch(apiUrl)
                  .then((res) => res.blob())
                  .then((blob) => {
                    const blobUrl = URL.createObjectURL(blob);
                    FileSaver.saveAs(blobUrl, item.filename);
                  });
              }
            }}
          />
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    function getAttachments() {
      fetchSrcmApi({
        api: `/api/v3/events/${eventName}/attachments/`,
        client: "eventsClient",
      }).then((data) => {
        setAttachments(data.results);
      });
    }
    getAttachments();
  }, [eventName, fetchSrcmApi]);

  const renderItemColumn = (item, index, column) => {
    const fieldContent =
      column && column.fieldName ? item[column.fieldName] : "";
    switch (column.key) {
      case "SNo":
        return index + 1;
      case "filename":
        return fieldContent;
      case "description":
        return fieldContent;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Box>
        {attachments && attachments.length ? (
          <DetailsList
            columns={initialColumns}
            items={attachments}
            setKey="set"
            selectionMode={SelectionMode.none}
            onRenderItemColumn={renderItemColumn}
          />
        ) : (
          <Box sx={{ textAlign: "center", p: 3 }}>
            <h3>No attachments found.</h3>
          </Box>
        )}
      </Box>
    </Container>
  );
};

Attachments.propTypes = {
  eventid: PropTypes.string.isRequired,
};

export default Attachments;
