/** @jsx jsx */
import { jsx, Box, Select, Label, Container } from "theme-ui";
import React, { useState, useEffect, useCallback } from "react";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { inputStyles } from "sites-common/utils/fabricStyles";
import { get, find } from "lodash";
import PropTypes from "prop-types";
import AddSmsTemplate from "./AddSmsTemplate";

function TemplateSelection({
  eventId,
  refreshTemplates = () => {},
  closeModal = () => {},
  initValue = {},
}) {
  const [smsTempList, setSmsTempList] = useState([]);
  const [smsTemplate, setSmsTemplate] = useState({
    sms_template: "",
    ...initValue,
  });
  const { fetchSrcmApi } = useFetchSrcmApi();

  const getFixedSmsTemplates = useCallback(() => {
    fetchSrcmApi({
      api: `/api/v3/sms-fixed-templates/`,
      client: "eventsClient",
    }).then((data) => {
      setSmsTempList(data.results);
    });
  }, [fetchSrcmApi]);

  useEffect(() => {
    getFixedSmsTemplates();
  }, [eventId, getFixedSmsTemplates]);

  const handleTempChange = (event) => {
    const sValue = parseInt(event.target.value, 10);
    setSmsTemplate((prevState) => ({
      ...prevState,
      sms_template: sValue || "",
    }));
  };

  let objSchema = {};
  let objSchemaValid = {};

  const selectedTemp = smsTemplate.sms_template
    ? find(smsTempList, ["id", parseInt(smsTemplate.sms_template, 10)])
    : null;

  if (selectedTemp) {
    selectedTemp.variable_fields.map((item) => {
      objSchema = {
        ...objSchema,
        ...{
          [item]: {
            value: get(initValue, `sms_field_mappings[${item}]`, ""),
            error: "",
          },
        },
      };
      objSchemaValid = {
        ...objSchemaValid,
        ...{ [item]: { required: true, error: `${item} can't be empty` } },
      };
      return null;
    });
  }

  return (
    <Container>
      <Box mb={3}>
        <Label htmlFor="smsTemplate" mb={2}>
          Templates <span sx={inputStyles.required}>*</span>
        </Label>
        <Select
          sx={inputStyles.inputStyle}
          name="smsTemplate"
          id="smsTemplate"
          value={smsTemplate.sms_template}
          onChange={handleTempChange}
        >
          <option readOnly value="" sx={{ color: "#848484" }}>
            Choose your template
          </option>
          {smsTempList.map((temp) => (
            <option key={temp.name} value={temp.id}>
              {temp.name}
            </option>
          ))}
        </Select>
      </Box>
      {smsTemplate.sms_template && selectedTemp ? (
        <Box mb={3}>
          <AddSmsTemplate
            eventId={eventId}
            refreshTemplates={refreshTemplates}
            closeModal={closeModal}
            initValue={smsTemplate}
            fSchema={objSchema}
            fValidatorSchema={objSchemaValid}
            fSelectedTemp={selectedTemp}
          />
        </Box>
      ) : null}
    </Container>
  );
}

TemplateSelection.defaultProps = {
  initValue: {},
};

TemplateSelection.propTypes = {
  eventId: PropTypes.string.isRequired,
  refreshTemplates: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  initValue: PropTypes.objectOf(PropTypes.any),
};

export default React.memo(TemplateSelection);
