import React, { useState, useEffect, useRef } from "react";
import DynamicForm from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { DefaultButton } from "office-ui-fabric-react";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import PropTypes from "prop-types";
import { useFirebase } from "react-redux-firebase";

import {
  TableHead,
  TableCell,
  Table,
  TableRow,
} from "@heartfulnessinstitute/react-hfn-forms/dist/ui/DivTable";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Spinner } from "theme-ui";
import {
  eventFormvalidations,
  paidSkuFields,
} from "./crud-shared/eventFormFields";

const submitButton = (
  <DefaultButton type="submit" text="Submit" styles={buttonStyles.blueLight} />
);

const discardButton = (
  <DefaultButton type="submit" text="Discard" styles={buttonStyles.default} />
);

function AddPricing({ item, onDiscard }) {
  const [pricingData, setPricing] = useState({
    loading: true,
    s: { paid_skus: [] },
  });
  const { showAlert } = useAlert();
  const firebase = useFirebase();
  const shopventory = useRef(null);

  const formDef = {
    fields: [...paidSkuFields],
  };

  useEffect(() => {
    if (!shopventory.current) {
      shopventory.current = firebase.functions().httpsCallable("shopventory");
    }
  }, [firebase]);

  useEffect(() => {
    if (pricingData.loading) {
      shopventory
        .current({
          request: "get-admin-shopventory",
          payload: { stock_group: item.name },
        })
        .then((result) => {
          setPricing({ loading: false, s: { paid_skus: result.data.skus } });
        });
    }
  }, [item.name, pricingData.loading]);

  const onSetupPricing = (data, successCallback) => {
    const afterUpdate = () => {
      successCallback();
      onDiscard();
      const alertInfo = {
        title: "Success",
        message: `Pricing has been successfully updated.`,
        confirm_text: "Okay",
        is_blocking: true,
      };

      showAlert(alertInfo);
    };

    const afterFailure = () => {
      onDiscard();
    };

    shopventory
      .current({
        request: "set-admin-shopventory",
        payload: { stock_group: item.name, skus_units: data.paid_skus },
      })
      .then((result) => {
        if (result.data.status === "success") {
          afterUpdate();
        } else {
          afterFailure();
        }
      });
  };

  if (pricingData.loading) {
    return (
      <>
        <Spinner color="buttonBg" size={35} />
        ...
      </>
    );
  }

  return (
    <>
      <DynamicForm
        formClassName="form"
        formLayout={null}
        defaultValues={pricingData.s}
        formFields={formDef.fields}
        formValidator={eventFormvalidations}
        inlineForm={{
          nolabels: true,
          container: (header, body) => (
            <Table>
              {header}
              {body}
            </Table>
          ),
          row: (children, ridx) => <TableRow key={ridx}>{children}</TableRow>,
          col: (children, cidx) => (
            <TableCell key={cidx} style={{ minWidth: 150 }}>
              {children}
            </TableCell>
          ),
          headcol: (children, hidx) => (
            <TableHead key={hidx}>
              <Label> {children}</Label>
            </TableHead>
          ),
        }}
        onSubmit={onSetupPricing}
        onDiscard={onDiscard}
        buttons={{ submitButton, discardButton }}
      />
    </>
  );
}

AddPricing.defaultProps = {
  item: {},
  // onSave: () => {},
  onDiscard: () => {},
};

AddPricing.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  // onSave: PropTypes.func,
  onDiscard: PropTypes.func,
};

export default AddPricing;
