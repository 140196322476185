/** @jsx jsx */
import { useState, useCallback } from "react";
import { jsx, Box, Container } from "theme-ui";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { doFormatShortDate } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import PropTypes from "prop-types";
import {
  DetailsList,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { validateEmail } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import {
  validateAbhyasiId,
  validatePhoneNumber,
} from "sites-common/utils/validations";
import DynamicSearch from "./DynamicSearch";

const DashboardRegistrants = ({ eventid }) => {
  const [eventName] = useState(eventid);
  const [error, setError] = useState("");
  const [registrations, setRegistrations] = useState([]);
  const { fetchSrcmApi } = useFetchSrcmApi();
  // const { showAlert } = useAlert();

  const handleSearch = useCallback(
    (searchBy, search) => {
      if (!search || !searchBy) {
        setError("Please fill all required fields.");
        return null;
      }

      if (searchBy === "email" && !validateEmail(search)) {
        setError("Please enter a valid email.");
        return null;
      }

      if (searchBy === "name" && search.length < 3) {
        setError("Please enter minimum 3 characters.");
        return null;
      }

      if (searchBy === "mobile" && !validatePhoneNumber(search)) {
        setError("Please enter minimum 3 digits.");
        return null;
      }

      if (searchBy === "ref" && !validateAbhyasiId(search)) {
        setError("Please enter a valid abhyasis id.");
        return null;
      }
      const methodParams = {
        [searchBy]: search,
      };

      fetchSrcmApi({
        api: `/api/v3/events/${eventName}/registrations/`,
        client: "eventsClient",
        methodParams,
      })
        .then((data) => {
          setRegistrations(data.results);
          setError("");
        })
        .catch(() => {});
      return null;
    },
    [fetchSrcmApi, eventName]
  );

  const ColumnItems = [
    {
      key: "pnr",
      fieldName: "pnr",
      name: "PNR",
      data: "string",
      isResizable: true,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      key: "name",
      fieldName: "name",
      name: "Name",
      data: "string",
      isResizable: true,
      minWidth: 180,
      maxWidth: 180,
    },
    {
      key: "email",
      fieldName: "email",
      name: "Email",
      data: "string",
      // isResizable: true,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "mobile",
      fieldName: "mobile",
      name: "Mobile",
      data: "string",
      isResizable: true,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      key: "stay_preference",
      fieldName: "stay_preference",
      name: "Stay Preference",
      data: "string",
      isResizable: true,
      minWidth: 130,
    },
    {
      key: "arrival_date",
      fieldName: "arrival_date",
      name: "Arrival date",
      data: "string",
      isResizable: true,
      minWidth: 150,
    },
    {
      key: "departure_date",
      fieldName: "departure_date",
      name: "Departure date",
      data: "string",
      isResizable: true,
      minWidth: 150,
    },
    {
      key: "create_date",
      fieldName: "create_date",
      name: "Registered on",
      data: "string",
      isResizable: true,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      key: "status",
      fieldName: "status",
      name: "Status",
      data: "string",
      isResizable: true,
      minWidth: 100,
      maxWidth: 100,
    },
  ];

  const renderApprovalItemColumn = useCallback((item, index, column) => {
    const fieldContent =
      column && column.fieldName ? item[column.fieldName] : "";
    // if (column.key === "city_id" && fieldContent) {
    //   fieldContent = getCityById(fieldContent);
    // }
    switch (column.key) {
      case "pnr":
      case "name":
      case "email":
      case "mobile":
      case "stay_preference":
      case "status":
        return fieldContent || "-";
      // case "city_id":
      //   return fieldContent || "-";
      case "create_date":
      case "arrival_date":
      case "departure_date":
        return fieldContent ? doFormatShortDate(fieldContent) : "-";
      default:
        return index;
    }
  }, []);

  const renderApprovalList = (
    <Box mt={3}>
      <DynamicSearch handleSearch={handleSearch} error={error} />

      {registrations && registrations.length ? (
        <DetailsList
          columns={ColumnItems}
          items={registrations}
          setKey="set"
          selectionMode={SelectionMode.none}
          onRenderItemColumn={renderApprovalItemColumn}
          compact
          enableUpdateAnimations
        />
      ) : (
        <Box sx={{ textAlign: "center", p: 3 }}>
          <h3>No records found.</h3>
        </Box>
      )}
    </Box>
  );

  return (
    <Container>
      <Box mt={3}>{renderApprovalList}</Box>
    </Container>
  );
};

DashboardRegistrants.propTypes = {
  eventid: PropTypes.string.isRequired,
};

export default DashboardRegistrants;
