/** @jsx jsx */
import {
  jsx,
  Box,
  Select,
  Label,
  Textarea,
  Input,
  Flex,
  Text,
  Spinner,
} from "theme-ui";
import React, { useState } from "react";
import { DefaultButton } from "office-ui-fabric-react";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { buttonStyles, inputStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import { get } from "lodash";
import handleError from "sites-common/utils/handleError";
import useFormValidations from "../utils/useFormValidations";

const AddSmsTemplate = ({
  eventId,
  refreshTemplates = () => {},
  closeModal = () => {},
  initValue = {},
  fSchema = {},
  fValidatorSchema = {},
  fSelectedTemp = {},
}) => {
  const formSchema = {
    ...fSchema,
    id: { value: get(initValue, "id", null), error: "" },
    sms_template: { value: get(initValue, "sms_template", null) },
    trigger_model: { value: "HfnEventRegistration", error: "" },
    trigger_field: { value: "status", error: "" },
    trigger_value: { value: get(initValue, "trigger_value", ""), error: "" },
    event: { value: eventId, error: "" },
  };

  const formValidatorSchema = {
    ...fValidatorSchema,
    sms_template: {
      required: true,
      error: "Templates can't be empty",
    },
    body_html: { required: true, error: "Content can't be empty" },
    // event_link: {
    //   required: true,
    //   error: "Event link can't be empty",
    //   validator: {
    //     func: (value) =>
    //       /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    //         value
    //       ),
    //     error: "Invalid event link",
    //   },
    // },
  };

  const smsBody = get(fSelectedTemp, "body", "");
  const variableFields = get(fSelectedTemp, "variable_fields", []);
  const [updating, setUpdating] = useState(false);
  const { fetchSrcmApi } = useFetchSrcmApi();
  const { showAlert } = useAlert();

  const submitFormCallback = (data) => {
    const {
      event,
      id,
      sms_template,
      trigger_field,
      trigger_model,
      trigger_value,
      ...otherProps
    } = data;
    const tempBody = {
      event,
      id,
      sms_template,
      trigger_field,
      trigger_model,
      trigger_value,
      sms_field_mappings: { ...otherProps },
    };
    setUpdating(true);
    const afterUpdate = () => {
      const alertInfo = {
        title: "Success",
        message: `Template has been successfully ${
          tempBody && tempBody.id ? "updated" : "created"
        }.`,
        confirm_text: "Okay",
        is_blocking: true,
      };
      refreshTemplates();
      closeModal();
      setUpdating(false);
      showAlert(alertInfo);
    };

    let apiUrl = `/api/v3/events/${eventId}/sms/`;
    if (tempBody && tempBody.id) {
      apiUrl = `/api/v3/events/${eventId}/sms/${tempBody.id}/`;
    } else {
      delete tempBody.id;
    }

    if (tempBody.trigger_value === "") {
      tempBody.trigger_value = null;
    }

    fetchSrcmApi({
      api: apiUrl,
      method: tempBody && tempBody.id ? "PUT" : "POST",
      methodParams: tempBody,
      client: "eventsClient",
    })
      .then(() => {
        afterUpdate();
      })
      .catch((error) => {
        const alertInfo = {
          title: "Error",
          message: handleError(error),
          confirm_text: "Okay",
          is_blocking: true,
        };
        closeModal();
        setUpdating(false);
        showAlert(alertInfo);
      });
  };

  const { values, errors, dirty, disable, handleOnChange, handleOnSubmit } =
    useFormValidations(formSchema, formValidatorSchema, submitFormCallback);

  const { trigger_value } = values;

  return (
    <React.Fragment>
      <Box>
        <Box mb={3}>
          <Label htmlFor="body_html" mb={2}>
            Content
          </Label>
          <Textarea
            readOnly
            sx={inputStyles.inputStyle}
            style={
              errors.body_html && dirty.body_html
                ? inputStyles.inputError
                : null
            }
            name="body_html"
            id="body_html"
            rows={5}
            value={smsBody}
          />
          <small sx={{ marginTop: "3px", color: "rgb(100 100 100)" }}>
            For variable substitution refer below fields
          </small>
          {errors.body_html && dirty.body_html && (
            <Text sx={inputStyles.errorMessage}>{errors.body_html}</Text>
          )}
        </Box>

        {/* Dynamic forms */}
        {variableFields.map((item) => (
          <Box mb={3} key={item}>
            <Label htmlFor={item} mb={2}>
              {item} <span sx={inputStyles.required}>*</span>
            </Label>
            <Input
              sx={inputStyles.inputStyle}
              style={
                errors[item] && dirty[item] ? inputStyles.inputError : null
              }
              name={item}
              id={item}
              value={values[item]}
              onChange={handleOnChange}
            />
            {errors[item] && dirty[item] && (
              <Text sx={inputStyles.errorMessage}>{errors[item]}</Text>
            )}
          </Box>
        ))}

        <Box mb={3}>
          <Label htmlFor="trigger_value" mb={2}>
            Trigger
          </Label>
          <Select
            sx={inputStyles.inputStyle}
            style={
              errors.trigger_value && dirty.trigger_value
                ? inputStyles.inputError
                : null
            }
            name="trigger_value"
            id="trigger_value"
            value={trigger_value}
            onChange={handleOnChange}
          >
            <option value="">Default</option>
            <option value="confirmed">Confirmed</option>
            <option value="approval pending">Pending</option>
            <option value="cancelled">Cancelled</option>
          </Select>
          {errors.trigger_value && dirty.trigger_value && (
            <Text sx={inputStyles.errorMessage}>{errors.trigger_value}</Text>
          )}
        </Box>
        <Flex sx={{ gap: 3, float: "right", pb: 3 }}>
          {updating && <Spinner size={35} />}
          <DefaultButton
            disabled={disable || updating}
            styles={buttonStyles.default}
            onClick={closeModal}
          >
            Cancel
          </DefaultButton>
          <DefaultButton
            disabled={disable || updating}
            styles={buttonStyles.blueLight}
            onClick={handleOnSubmit}
          >
            Submit
          </DefaultButton>
        </Flex>
      </Box>
    </React.Fragment>
  );
};

AddSmsTemplate.defaultProps = {
  initValue: {},
  fSchema: {},
  fValidatorSchema: {},
  fSelectedTemp: {},
};

AddSmsTemplate.propTypes = {
  eventId: PropTypes.string.isRequired,
  refreshTemplates: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  initValue: PropTypes.objectOf(PropTypes.any),
  fSchema: PropTypes.objectOf(PropTypes.any),
  fValidatorSchema: PropTypes.objectOf(PropTypes.any),
  fSelectedTemp: PropTypes.objectOf(PropTypes.any),
};

export default React.memo(AddSmsTemplate);
