import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { profileApiUrl } from "sites-common/service-wrappers/profile-api";
import ServerAutoSuggest from "sites-common/components/ui/ServerAutoSuggest";
import { useFetchSrcmApi } from "../../../fetch-srcm-api";
// import { profileApiCachedUrl } from "../../../lib/var";

const dflt = [];

const AbhyasiSearch = ({ includeOnly, ...restprops }) => {
  const { fetchSrcmApi, fetchParams } = useFetchSrcmApi();

  const getConfigsObj = useMemo(() => {
    const apiParams = () => fetchParams({ api: `abhyasis/search` });

    const fetchAbhyasiWithId = (id) => {
      return fetchSrcmApi({ api: `abhyasis/search/${id}` });
    };

    const config = {
      dflt: includeOnly ? dflt.filter(includeOnly) : dflt,
      fetch: fetchAbhyasiWithId,
      // Trigger suggestions
      getSuggestionValue: (suggestion) => suggestion.name,
      // Render Each Option
      renderSuggestion: (suggestion) => `${suggestion.name} ${suggestion.ref}`,
      api: (itrimValue) =>
        profileApiUrl(
          "abhyasis-search",
          "ref",
          itrimValue.toUpperCase().trim()
        ),
      apiParams,
      data2results: (data /* , itrimValue, getSuggestionValue, dflt */) => [
        ...data.results.filter((f) => !includeOnly || includeOnly(f)),
      ],
    };

    return config;
  }, [fetchParams, fetchSrcmApi, includeOnly]);
  return <ServerAutoSuggest {...restprops} config={getConfigsObj} />;
};

AbhyasiSearch.propTypes = {
  includeOnly: PropTypes.func,
};
AbhyasiSearch.defaultProps = {
  includeOnly: null,
};
export default AbhyasiSearch;
