import React from "react";
import PropTypes from "prop-types";
import { Flex, Link } from "theme-ui";

const MissedCallBlock = ({ teleNumber, displayNumber, text, show }) =>
  show ? (
    <Flex
      sx={{
        bg: "primary",
        color: "background",
        alignItems: "center",
        justifyContent: "center",
        fontSize: ["12px", "14px"],
        lineHeight: ["12px", "14px"],
      }}
    >
      <Flex>{text}</Flex>
      <Flex>
        <Link
          style={{ textDecoration: "none" }}
          href={`tel:${teleNumber}`}
          variant="variant1"
        >
          {displayNumber}
        </Link>
      </Flex>
    </Flex>
  ) : null;

MissedCallBlock.propTypes = {
  teleNumber: PropTypes.string,
  text: PropTypes.string,
  displayNumber: PropTypes.string,
  show: PropTypes.bool,
};

MissedCallBlock.defaultProps = {
  teleNumber: "",
  displayNumber: "",
  text: "Give a missed call to: ",
  show: false,
};

export default React.memo(MissedCallBlock);
