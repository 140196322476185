/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui";
import React from "react";
import { Dropdown, ActionButton, DefaultButton } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { buttonStyles } from "sites-common/utils/fabricStyles";

const Pagination = ({
  pageSize = 50,
  setPageSize,
  setPage,
  page = 0,
  TotalPageCount,
  search,
  setSearch,
  csvData,
  toSearch = false,
  toCsv = false,
}) => {
  const rowitemsInput = [
    { key: "10", text: 10 },
    { key: "50", text: 50 },
    { key: "100", text: 100 },
    { key: "200", text: 200 },
    // { key: "500", text: 500 },
  ];

  const incrPage = () => {
    setPage((p) => p + 1);
  };

  const decrPage = () => setPage((p) => p - 1);

  const handlePage = (e, v) => {
    setPageSize(v.text);
    setPage(1);
  };

  return (
    <React.Fragment>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: ["column", null, "row"],
          px: 2,
          py: 1,
        }}
      >
        {toSearch && (
          <Flex sx={{ justifyContent: "space-around", alignItems: "center" }}>
            <DynamicField
              name="globalSearch"
              type="text"
              value={search}
              onChange={(e) => setSearch(e)}
              placeholder="Search"
              props={{
                placeholder: "Search",
                iconProps: {
                  iconName: "search",
                },
                required: false,
              }}
            />
          </Flex>
        )}

        <Flex sx={{ justifyContent: "space-around", alignItems: "center" }}>
          <Box>
            {page !== 0 && (
              <ActionButton
                title="Previous Page"
                iconProps={{ iconName: "Back" }}
                text=""
                onClick={decrPage}
              />
            )}
          </Box>
          <Box>
            <small>
              Showing {TotalPageCount ? page * pageSize + 1 : 0} -{" "}
              {page * pageSize + pageSize <= TotalPageCount
                ? page * pageSize + pageSize
                : TotalPageCount || 0}
              {" of "}
              {TotalPageCount || 0}
              {" results "}
            </small>
          </Box>
          <Box>
            {page !== Math.floor(TotalPageCount / pageSize) && (
              <ActionButton
                title="Next Page"
                iconProps={{ iconName: "Forward" }}
                text=""
                onClick={incrPage}
              />
            )}
          </Box>
        </Flex>

        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          <small>Rows per page</small>
          <Dropdown
            style={{ minWidth: "60px" }}
            options={rowitemsInput}
            sx={{ mx: 2 }}
            selectedKey={pageSize.toString()}
            onChange={handlePage}
          />
        </Box>

        {toCsv && (
          <DefaultButton styles={buttonStyles.blueLight}>
            <CSVLink
              data={csvData}
              filename="Attendance.csv"
              sx={{ color: "#fff", textDecoration: "none" }}
            >
              Download
            </CSVLink>
          </DefaultButton>
        )}
      </Flex>
    </React.Fragment>
  );
};

Pagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  TotalPageCount: PropTypes.number.isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  // exportCsv: PropTypes.func,
  csvData: PropTypes.shape([]),
  toSearch: PropTypes.bool,
  toCsv: PropTypes.bool,
  // fields: PropTypes.shape([]),
};

Pagination.defaultProps = {
  search: "",
  setSearch: () => {},
  toSearch: false,
  toCsv: false,
  // exportCsv: () => {},
  csvData: [],
};

export default Pagination;
