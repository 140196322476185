/** @jsx jsx */
import { jsx, Box, Label, Container, Grid, Divider } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";
import { upperFirst } from "lodash";
import moment from "moment";
import { genderList } from "../utils/constants";
import { genderValue } from "../utils/common";

function ViewRegistrant({ registrantData, fields }) {
  const getVal = (data) => {
    if (typeof data === "string") return data;
    return true;
  };

  const renderItem = (lbl, val, key) => {
    let value = "";
    switch (key) {
      case "gender":
        value = genderValue(genderList, val) || "";
        break;
      case "ref":
        value = val === "B99999999" ? "" : val;
        break;
      case "create_date":
        value = moment(val).format("MMM DD, YYYY");
        break;
      default:
        value = val;
    }

    const label = upperFirst(lbl.replaceAll("_", " "));
    return (
      <Grid key={label} gap={2} columns={[2, 4]}>
        <Box mb={3}>
          <Label htmlFor="body_html" mb={2}>
            <b>{label}</b>
          </Label>
        </Box>
        <Box mb={3}>
          <span htmlFor="body_html" mb={2}>
            {val ? getVal(value) : "-"}
          </span>
        </Box>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Container>
        <Divider />
        {registrantData &&
          fields &&
          fields.map((data) => {
            const label = data?.label;
            const value = registrantData[data.key];
            if (data?.key === "reg_json") {
              let resJson = {};
              if (typeof value === "string") {
                resJson = JSON.parse(value);
                return Object.keys(resJson).map((k) => {
                  if (k !== "gov_id") return renderItem(k, resJson[k]);
                  return true;
                });
              }
            } else return renderItem(label, value, data.key);
            return true;
          })}
      </Container>
    </React.Fragment>
  );
}
ViewRegistrant.defaultProps = {
  registrantData: {},
  fields: [{}],
};

ViewRegistrant.propTypes = {
  registrantData: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.arrayOf(PropTypes.any),
};

export default ViewRegistrant;
