/** @jsx jsx */
import {
  jsx,
  Flex,
  Box,
  Container,
  Textarea,
  Label,
  Input,
  Select,
  Checkbox,
  Spinner,
} from "theme-ui";
import React, { useState } from "react";
import { DefaultButton, IconButton } from "office-ui-fabric-react";
import { buttonStyles, inputStyles } from "sites-common/utils/fabricStyles";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import get from "lodash/get";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import { upperFirst } from "lodash";
import handleError from "sites-common/utils/handleError";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { mySrcmConfig } = getMandatoryEnv(["mySrcmConfig"]);
const { eventsClient, profileServer } = mySrcmConfig;

const RegistrationWidget = ({ eventid, eventInfo }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            domainApps
          }
        }
      }
    `
  );
  const { domainApps } = site.siteMetadata;
  const [optionalFieldsCheck, setOptionalFieldsCheck] = useState(
    get(eventInfo, "event_json.widget_optional_fields_check", [])
  );
  const [optionalFieldsCheckJson, setOptionalFieldsCheckJson] = useState([]);
  const [optionalFields, setOptionalFields] = useState(
    get(eventInfo, "event_json.widget_optional_fields", [])
  );
  const [optionalFieldsStr, setOptionalFieldsStr] = useState(
    JSON.stringify(get(eventInfo, "event_json.widget_optional_fields", []))
  );
  const [labelRequired, setLabelRequired] = useState(
    get(eventInfo, "event_json.widget_fields_label_required", false)
  );

  const generateRestrictionsJson = (eventObj) => {
    const resJson = [];
    get(eventObj, "event_json.constraints", []).forEach((constraint) => {
      const { condtion, field_type, error_msg, value } = constraint;
      const fType = `:${field_type}`;
      const resObj = {
        conds: [[fType, condtion, value]],
        msg: error_msg,
      };
      resJson.push(resObj);
    });
    return resJson;
  };

  const [constraints] = useState(
    JSON.stringify(generateRestrictionsJson(eventInfo))
  );

  const regScriptHTML = `<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script><script src="${domainApps}/assets/widget.js"></script><script src="${domainApps}/assets/widget-registration.js"></script><link href="${domainApps}/assets/widget-registration.css"rel="stylesheet"type="text/css"><script src="${domainApps}/assets/widget-registration-autocomplete.js"></script>`;
  const regContainerHTML = `<div id="reg-form-body" data-event="${encodeURIComponent(
    eventid
  )}" data-bgcolor="e1ffcc" data-titlecolor="fffff" data-title="Event Registration" data-btntext="Register" data-label-required="${encodeURIComponent(
    labelRequired
  )}" data-optional-fields-check="${encodeURIComponent(
    optionalFieldsCheck
  )}" data-optional-fields="${encodeURIComponent(
    optionalFieldsStr
  )}" data-constraints="${encodeURIComponent(
    constraints
  )}" data-api="${encodeURIComponent(
    profileServer
  )}/api/v3/" data-api-clientid="${encodeURIComponent(eventsClient)}" ></div>`;
  const [errors, setErrors] = useState({});
  const [updating, setUpdating] = useState(false);
  const { fetchSrcmApi } = useFetchSrcmApi();
  const { showAlert } = useAlert();

  const saveWidgetDetails = () => {
    const body = {
      event_json: {
        widget_fields_label_required: labelRequired,
        widget_optional_fields_check: optionalFieldsCheck,
        widget_optional_fields_check_json: optionalFieldsCheckJson,
        widget_optional_fields: optionalFields,
      },
    };
    setUpdating(true);
    const afterUpdate = () => {
      setUpdating(false);
      const alertInfo = {
        title: "Success",
        message: `Optional fields has been successfully saved.`,
        confirm_text: "Okay",
        is_blocking: true,
      };
      showAlert(alertInfo);
    };
    fetchSrcmApi({
      api: `/api/v3/events/${eventid}/`,
      method: "PATCH",
      methodParams: body,
      client: "eventsClient",
    })
      .then((data) => {
        afterUpdate();
        return data;
      })
      .catch((error) => {
        setUpdating(false);
        const alertInfo = {
          title: "Error",
          message: handleError(error),
          confirm_text: "Okay",
          is_blocking: true,
        };
        showAlert(alertInfo);
      });
  };

  const validateFields = (optFields) => {
    const err = {};
    if (!optFields.label) {
      err.label = "Label is required";
    }
    if (!optFields.field_name) {
      err.field_name = "Column Name is required";
    }
    if (!optFields.field_type) {
      err.field_type = "Field Type is required";
    }
    return err;
  };

  const handleCheckBoxChange = (event) => {
    let checked = optionalFieldsCheck || [];
    let checked2 = optionalFieldsCheckJson || [];
    const newField = {
      field_type: event.target.name,
      field_name: event.target.name,
      label: upperFirst(event.target.name),
      is_required: false,
    };
    if (event.target.checked) {
      checked = [...optionalFieldsCheck, ...[event.target.name]];
      checked2 = [...optionalFieldsCheckJson, ...[newField]];
    } else {
      const index = checked.findIndex((item) => item === event.target.name);
      checked2.splice(
        checked2.findIndex((item) => item.field_name === event.target.name),
        1
      );
      checked.splice(index, 1);
    }
    setOptionalFieldsCheck([...checked]);
    setOptionalFieldsCheckJson([...checked2]);
  };

  const handleChange = (index) => (e) => {
    const { name, value } = e.target;
    const rows = [...optionalFields];
    rows[index][name] =
      name === "field_name" ? value.replace(/\s/g, "") : value;
    setOptionalFields([...rows]);
  };

  const handleAddRow = () => {
    let noErrors = true;
    if (optionalFields.length !== 0) {
      const validationErrors = validateFields(
        optionalFields[optionalFields.length - 1]
      );
      noErrors = Object.keys(validationErrors).length === 0;
      setErrors(validationErrors);
    }
    if (noErrors) {
      const item = {
        label: "",
        field_name: "",
        field_type: "",
        is_required: false,
      };
      setOptionalFields([...optionalFields, item]);
    }
  };

  const saveFields = () => {
    let noErrors = true;
    if (optionalFields.length !== 0) {
      const validationErrors = validateFields(
        optionalFields[optionalFields.length - 1]
      );
      noErrors = Object.keys(validationErrors).length === 0;
      setErrors(validationErrors);
    }
    if (noErrors) {
      setOptionalFieldsStr(JSON.stringify(optionalFields));
      saveWidgetDetails();
    }
  };

  const handleRemoveRow = (index) => {
    optionalFields.splice(index, 1);
    setErrors({});
    setOptionalFields([...optionalFields]);
    saveFields();
  };

  return (
    <React.Fragment>
      <Container>
        <Box sx={{ p: 1, mb: 2 }}>
          <Textarea
            sx={{ fontSize: "14px" }}
            value={regScriptHTML}
            rows={6}
            readOnly
          />
          <small sx={{ color: "rgb(96 94 92)" }}>
            Copy this script and add it to the {"<head>"} block.
          </small>
        </Box>
        <Box sx={{ p: 1 }}>
          <Textarea
            sx={{ fontSize: "14px" }}
            value={regContainerHTML}
            rows={6}
            readOnly
          />
          <small sx={{ color: "rgb(96 94 92)" }}>
            Copy paste the content wherever the form is required to be rendered.
          </small>
        </Box>
        <Box>
          <Box sx={{ p: 2, fontSize: "14px" }}>
            <Label>
              <b>Optional Fields:</b>
            </Label>
            <Flex
              sx={{
                gap: 3,
                mt: 3,
                flexDirection: "row",
                "@media (max-width: 480px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Box>
                <Label>
                  <Checkbox
                    name="gender"
                    onChange={handleCheckBoxChange}
                    checked={optionalFieldsCheck.indexOf("gender") > -1}
                  />
                  Gender
                </Label>
              </Box>
              <Box>
                <Label>
                  <Checkbox
                    name="age_group"
                    onChange={handleCheckBoxChange}
                    checked={optionalFieldsCheck.indexOf("age_group") > -1}
                  />
                  Age
                </Label>
              </Box>
              <Box>
                <Label>
                  <Checkbox
                    name="communication_preference"
                    onChange={handleCheckBoxChange}
                    checked={
                      optionalFieldsCheck.indexOf("communication_preference") >
                      -1
                    }
                  />
                  Communication Preference
                </Label>
              </Box>
            </Flex>
          </Box>
          <Box sx={{ p: 2, fontSize: "14px" }}>
            <Label>
              <b>Form Labels:</b>
            </Label>
            <Box>
              <Flex sx={{ gap: 2, mt: 3 }}>
                <Box>
                  <Label>
                    <Checkbox
                      name="form-labels"
                      onChange={() => setLabelRequired(!labelRequired)}
                      checked={labelRequired}
                    />
                    Required
                  </Label>
                </Box>
              </Flex>
            </Box>
          </Box>

          <div style={{ overflow: "auto" }}>
            <table sx={{ width: "100%", mt: 3, fontSize: "14px" }}>
              <thead sx={{ textAlign: "left" }}>
                <tr sx={{ background: "#dddddd" }}>
                  <th
                    sx={{ padding: "8px", width: "220px", minWidth: "220px" }}
                  >
                    Field Type
                  </th>
                  <th
                    sx={{ padding: "8px", width: "200px", minWidth: "200px" }}
                  >
                    Label
                  </th>
                  <th
                    sx={{ padding: "8px", width: "200px", minWidth: "200px" }}
                  >
                    Field Name
                  </th>
                  <th
                    sx={{ padding: "8px", width: "200px", minWidth: "200px" }}
                  >
                    Is Required
                  </th>
                  <th sx={{ padding: "8px", width: "90px", minWidth: "90px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {optionalFields &&
                  optionalFields.map(
                    (item, index) =>
                      item.field_name !== "gender" &&
                      item.field_name !== "age_group" &&
                      item.field_name !== "communication_preference" && (
                        <tr>
                          <td sx={{ padding: "8px" }}>
                            <Label />
                            <Select
                              style={
                                errors.field_type &&
                                optionalFields.length - 1 === index
                                  ? inputStyles.inputError
                                  : null
                              }
                              my={3}
                              sx={inputStyles.inputStyle}
                              name="field_type"
                              id="field_type"
                              value={optionalFields[index].field_type}
                              onChange={handleChange(index)}
                              aria-label="field_type"
                            >
                              <option
                                value=""
                                disabled
                                sx={{ color: "#848484" }}
                              >
                                Choose your field type
                              </option>
                              <option value="text">Text</option>
                              <option value="textarea">Textarea</option>
                            </Select>
                          </td>
                          <td sx={{ padding: "8px" }}>
                            <Input
                              style={
                                errors.label &&
                                optionalFields.length - 1 === index
                                  ? inputStyles.inputError
                                  : null
                              }
                              my={3}
                              sx={inputStyles.inputStyle}
                              type="text"
                              name="label"
                              id="label"
                              value={optionalFields[index].label}
                              onChange={handleChange(index)}
                              aria-label="label"
                            />
                          </td>
                          <td sx={{ padding: "8px" }}>
                            <Input
                              style={
                                errors.field_name &&
                                optionalFields.length - 1 === index
                                  ? inputStyles.inputError
                                  : null
                              }
                              my={3}
                              sx={inputStyles.inputStyle}
                              type="text"
                              name="field_name"
                              id="field_name"
                              value={optionalFields[index].field_name}
                              onChange={handleChange(index)}
                              aria-label="field_name"
                            />
                          </td>
                          <td sx={{ padding: "8px" }}>
                            <Select
                              my={3}
                              sx={inputStyles.inputStyle}
                              name="is_required"
                              id="is_required"
                              value={optionalFields[index].is_required}
                              onChange={handleChange(index)}
                              aria-label="is_required"
                            >
                              <option value="false">No</option>
                              <option value="true">Yes</option>
                            </Select>
                          </td>
                          <td sx={{ padding: "8px" }}>
                            <Flex
                              my={3}
                              sx={{ gap: 2, justifyContent: "center" }}
                            >
                              <IconButton
                                iconProps={{ iconName: "Delete" }}
                                onClick={() => handleRemoveRow(index)}
                                disabled={updating}
                              >
                                Delete
                              </IconButton>
                            </Flex>
                          </td>
                        </tr>
                      )
                  )}
              </tbody>
            </table>
          </div>

          <Flex sx={{ gap: 2, my: 3 }}>
            <Box sx={{ flex: "1 1 auto" }}>
              <IconButton
                iconProps={{ iconName: "Add" }}
                onClick={handleAddRow}
                disabled={updating}
              >
                Add
              </IconButton>
            </Box>
            <Flex
              sx={{ flexDirection: "row", justifyContent: "center", gap: 2 }}
            >
              {updating && <Spinner size={35} />}
              <DefaultButton
                styles={buttonStyles.blueLight}
                onClick={saveFields}
                text="Save"
                disabled={updating}
              />
            </Flex>
          </Flex>
        </Box>
      </Container>
    </React.Fragment>
  );
};

RegistrationWidget.defaultProps = {
  eventInfo: {},
};

RegistrationWidget.propTypes = {
  eventid: PropTypes.string.isRequired,
  eventInfo: PropTypes.objectOf(PropTypes.any),
};

export default RegistrationWidget;
