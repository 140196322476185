/** @jsx jsx */
import { jsx, Box, Container, Flex } from "theme-ui";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";
import { buttonStyles } from "sites-common/utils/fabricStyles";
import { DefaultButton } from "office-ui-fabric-react";
import moment from "moment";
import { get } from "lodash";

const Attendance = ({ eventid }) => {
  const [attendance, setAttendance] = useState([]);
  const { fetchSrcmApi } = useFetchSrcmApi();

  const downloadCsvHeaders = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Registered on", key: "create_date" },
    { label: "Attendance Date", key: "attendance_date" },
  ];

  useEffect(() => {
    function getAttendance() {
      fetchSrcmApi({
        api: `/api/v3/events/${eventid}/attendance/`,
        client: "eventsClient",
      }).then((data) => {
        const results = [];
        get(data, "results", []).forEach((item) => {
          const dataR = {
            name: item.name,
            email: item.email,
            mobile: item.mobile,
            create_date: moment(item.create_date).format("YYYY-MM-DD"),
            attendance_date: item.attendance_date,
          };
          results.push(dataR);
        });
        setAttendance(results);
      });
    }
    getAttendance();
  }, [fetchSrcmApi, eventid]);

  return (
    <Container>
      {attendance.length ? (
        <Flex
          sx={{
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h3>{`Total attendance count: ${attendance.length}`}</h3>

          <CSVLink
            headers={downloadCsvHeaders}
            data={attendance}
            filename="attendance-list.csv"
          >
            <DefaultButton
              styles={buttonStyles.blueLight}
              iconProps={{ iconName: "Download" }}
              text="Download Attendance"
              target="_blank"
            />
          </CSVLink>
        </Flex>
      ) : (
        <Box sx={{ textAlign: "center", p: 3 }}>
          <h3>No attendance found.</h3>
        </Box>
      )}
    </Container>
  );
};

export default Attendance;

Attendance.propTypes = {
  eventid: PropTypes.string.isRequired,
};
