/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Radio, Label, Text } from "theme-ui";

const RegisterViaRadioLayout = React.forwardRef(
  (
    {
      show,
      checkedEmail,
      checkedMobile,
      onChange,
      radioTitle,
      emailLabel,
      mobileLabel,
    },
    ref
  ) =>
    show ? (
      <Box
        sx={{
          backgroundColor: "primary",
          color: "background",
          margin: "28px 0",
        }}
      >
        <Text
          variant="inverted"
          sx={{
            fontSize: ".8rem",
            fontWeight: "body",
          }}
          as="p"
        >
          {radioTitle}
        </Text>
        <Box variant="boxes.primaryRadio">
          <Label>
            <Radio
              name="regMedium"
              checked={checkedEmail}
              onChange={onChange}
              value="email"
              ref={ref}
            />{" "}
            {emailLabel}
          </Label>
        </Box>
        <Box variant="boxes.primaryRadio">
          <Label>
            <Radio
              name="regMedium"
              checked={checkedMobile}
              onChange={onChange}
              value="mobile"
              ref={ref}
            />{" "}
            {mobileLabel}
          </Label>
        </Box>
      </Box>
    ) : null
);

RegisterViaRadioLayout.propTypes = {
  show: PropTypes.bool,
  checkedEmail: PropTypes.bool,
  checkedMobile: PropTypes.bool,
  onChange: PropTypes.func,
  radioTitle: PropTypes.string,
  emailLabel: PropTypes.string,
  mobileLabel: PropTypes.string,
};

const RegisterViaRadio = React.forwardRef(({ show, onChange }, ref) => {
  const [state, setState] = React.useState({
    value: "email",
    checkedEmail: true,
    checkedMobile: false,
  });

  const { t } = useTranslation();

  const registerViaRadio = {
    ref,
    show,
    radioTitle: t("Register via"),
    emailLabel: t("Email Address"),
    mobileLabel: t("Mobile"),
    ...state,
    onChange: React.useCallback((evt) => {
      const payload = evt.target.value;
      setState((currentState) => ({
        ...currentState,
        checkedEmail: payload === "email",
        checkedMobile: payload === "mobile",
        value: payload,
      }));
    }, []),
  };

  React.useEffect(() => {
    onChange && onChange(state.value);
  }, [state.value]);

  return <RegisterViaRadioLayout {...registerViaRadio} />;
});

RegisterViaRadio.propTypes = {
  show: PropTypes.bool,
  onChange: PropTypes.func,
};

RegisterViaRadio.defaultProps = {
  show: false,
};

export default React.memo(RegisterViaRadio);
