import React from "react";
import PropTypes from "prop-types";
import { Radio, Label } from "theme-ui";
import { noop } from "sites-common/utils/lodash";

export const useRadios = (args = {}) => {
  const {
    options = [],
    value: currentValue = "",
    name = "",
    onChange = noop,
    ...restArgs
  } = args;

  const getRadioConfig = React.useCallback(
    ({ value, label, ...restOptions }) => {
      return {
        type: "radio",
        value,
        label,
        name,
        onChange: (evt) => onChange(evt.target.value, evt),
        defaultChecked: value === currentValue,
        ...restArgs,
        ...restOptions,
      };
    },
    [currentValue, name, onChange, restArgs]
  );
  const radios = React.useMemo(
    () => options.map(getRadioConfig),
    [options, getRadioConfig]
  );
  return radios;
};

const RadioItem = ({ label, ...restProps }) => (
  <Label sx={{ alignItems: "center" }}>
    <Radio {...restProps} />
    {label}
  </Label>
);

RadioItem.propTypes = {
  label: PropTypes.shape(PropTypes.any),
};

RadioItem.defaultProps = {
  label: "",
};

const Radios = ({ value, options, name, onChange, onClick }) => {
  const radios = useRadios({
    name,
    value,
    options,
    onChange,
    onClick,
  });

  return (
    <>
      {Array.isArray(radios)
        ? radios.map((radio) => <RadioItem key={radio.value} {...radio} />)
        : null}
    </>
  );
};

Radios.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

Radios.defaultProps = {
  value: "",
  options: [],
  name: "",
  onChange: () => {},
  onClick: () => {},
};

export default Radios;
