/** @jsx jsx */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { jsx, Flex, Box, Container, Spinner } from "theme-ui";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
import PropTypes from "prop-types";
import {
  DefaultButton,
  Dialog,
  DialogType,
  Icon,
} from "office-ui-fabric-react";
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import {
  DetailsList,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import handleError from "sites-common/utils/handleError";
import useFetchOmsApi from "gatsby-plugin-hfn-profile/components/Hooks/useFetchOmsApi";
import moment from "moment";
import ViewRegistrant from "./ViewRegistrant";
import { appsDialogStyles } from "../utils/dialogStyles";
import Pagination from "../utils/pagination";

import { genderList } from "../utils/constants";
import { genderValue, getOrderByStatus } from "../utils/common";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

const DashboardPendingApprovals = ({
  eventid,
  approvalLevel,
  paidEvent,
  approvalOnPayment,
}) => {
  const [updating, setUpdating] = useState(false);
  const [partiallyApproved, setPartiallyApproved] = useState(false);
  const [registrations, setRegistrations] = useState([]);
  const [viewmore, setViewmore] = useState(false);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState([]);
  const { fetchSrcmApi } = useFetchSrcmApi();
  const { showAlert } = useAlert();
  const orderConfirmed = "ORDER_CONFIRMED";

  const { fetchOmsApi: fetchOmsApiKanha } = useFetchOmsApi({
    provider: "kanhaOMS",
  });

  // Pagination
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  // Search
  const [search, setSearch] = useState("");

  const [showDialog, setShowDialog] = useState(null);
  const [approve, setApprove] = useState(false);
  const [items, setItems] = useState();

  const eventName = useMemo(() => eventid, [eventid]);
  const activeState = useMemo(() => "pending", []);
  const spinerWrapper = {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    minHeight: "300px",
  };

  const getRegistrations = useCallback(() => {
    setLoader(true);
    fetchSrcmApi({
      api: `/api/v3/events/${eventName}/pnrs/`,
      client: "eventsClient",
      methodParams: {
        status: activeState,
        page: `${page}`,
        page_size: `${pageSize}`,
        pnr: search || "",
      },
    }).then((data) => {
      setLoader(false);
      setRegistrations(data.results);
      setCount(data.count);
    });
  }, [fetchSrcmApi, eventName, pageSize, page, search, activeState]);

  const viewFields = [
    { key: "name", label: "Name", width: "120px" },
    { key: "age_group", label: "Age", width: "40px" },
    { key: "gender", label: "Gender", width: "60px" },
    { key: "ref", label: "Abhyasi ID", width: "80px" },
    { key: "city_name", label: "City", width: "60px" },
    { key: "email", label: "Email", width: "160px" },
    { key: "mobile", label: "Mobile", width: "100px" },
    { key: "alt_mobile", label: "Alt Mobile", width: "100px" },
    { key: "date_of_joining", label: "DOJ", width: "80px" },
    { key: "payment_status", label: "Payment Status", width: "100px" },
    { key: "create_date", label: "Reg on", width: "80px" },
  ];

  if (approvalLevel === "INDIVIDUAL")
    viewFields.push({ key: "action", label: "Action", width: "120px" });

  const iconProps = {
    width: 25,
    cursor: "pointer",
    fontWeight: "bold",
  };
  const pendingRegUl = {
    paddingInlineStart: "0px",
  };

  const successMessage = (action) => {
    const act = action === "approve" ? "approved" : "rejected";
    const successMsg = partiallyApproved
      ? `Event registration has been partially ${act}`
      : `Event registration has been successfully ${act}.`;
    const alertInfo = {
      title: "Success",
      message: successMsg,
      confirm_text: "Okay",
      is_blocking: true,
    };
    setUpdating(false);
    showAlert(alertInfo);
    getRegistrations();
    setShowDialog(null);
  };
  const getOrderByPnr = (pnr, action) => {
    fetchOmsApiKanha({
      api: `/order-by-pnr/${pnr}/`,
      method: "GET",
    }).then((data) => {
      if (data?.order && data?.order.length > 0) {
        const res = getOrderByStatus(data?.order, orderConfirmed);
        if (res?.id) {
          if (action === "approve") {
            fetchOmsApiKanha({
              api: `/admin-confirmation-email-notification`,
              method: "POST",
              methodParams: { orderId: res?.id },
            }).then((aData) => {
              return aData;
            });
          } else if (action === "reject" && res?.items?.length > 0) {
            const cancelParam = {
              orderId: res?.id,
              cancelledOrderItemIds: [res?.items[0].id],
              cancellationCharge: {
                refundPercentage: 100,
                gstPercentage: 18,
                gstBreakupPercent: {
                  cgst: 9,
                  sgst: 9,
                },
              },
            };
            fetchOmsApiKanha({
              api: `/admin-order-override-cancel`,
              method: "POST",
              methodParams: cancelParam,
            }).then((cData) => {
              return cData;
            });
          }
        }
      }
      successMessage(action);
    });
  };

  const onApprove = async (event, item) => {
    event.preventDefault();
    setUpdating(true);
    const body = {
      status: "confirmed",
    };
    const totalCount = item?.registrations.length;
    let sucessCount = 0;
    await Promise.all(
      item?.registrations.map(async (i) => {
        if (i?.status === "pending") {
          await fetchSrcmApi({
            api: `/api/v3/events/${eventName}/registrations/${i?.id}/`,
            method: "PATCH",
            methodParams: body,
            client: "eventsClient",
          })
            .then((data) => {
              sucessCount += 1;
              return data;
            })
            .catch((error) => {
              const alertInfo = {
                title: "Error",
                message: handleError(error),
                confirm_text: "Okay",
                is_blocking: true,
              };
              setUpdating(false);
              setShowDialog(null);
              showAlert(alertInfo);
            });
        }
        return true;
      })
    ).then(() => {
      const isPartial = !!(sucessCount > 1 && sucessCount !== totalCount);
      setPartiallyApproved(isPartial);
      if (sucessCount > 0 && paidEvent)
        getOrderByPnr(item?.registrations[0]?.pnr, "approve");
      else if (sucessCount > 0) successMessage("approve");
    });
  };

  const onReject = async (event, item) => {
    event.preventDefault();
    setUpdating(true);

    const body = {
      status: "not_approved",
    };
    const totalCount = item?.registrations.length;
    let sucessCount = 0;
    await Promise.all(
      item?.registrations.map(async (i) => {
        if (i?.status === "pending") {
          await fetchSrcmApi({
            api: `/api/v3/events/${eventName}/registrations/${i?.id}/`,
            method: "PATCH",
            methodParams: body,
            client: "eventsClient",
          })
            .then((data) => {
              sucessCount += 1;
              return data;
            })
            .catch(() => {
              const alertInfo = {
                title: "Error",
                message: `Sorry, Something went wrong. Please try again later.`,
                confirm_text: "Okay",
                is_blocking: true,
              };
              setUpdating(false);
              setShowDialog(null);
              showAlert(alertInfo);
            });
        }
        return true;
      })
    ).then(() => {
      const isPartial = !!(sucessCount > 1 && sucessCount !== totalCount);
      setPartiallyApproved(isPartial);
      if (sucessCount > 0 && paidEvent)
        getOrderByPnr(item?.registrations[0]?.pnr, "reject");
      else if (sucessCount > 0) successMessage("reject");
    });
  };

  useEffect(() => {
    getRegistrations();
  }, [fetchSrcmApi, getRegistrations, eventName]);

  const checkPendingRegistrant = (allReg) => {
    const res = allReg.find(({ status }) => status === "pending");
    if (typeof res === "object") return true;
    return false;
  };

  const getPaymentStatus = (allReg) => {
    const res = allReg.find(
      ({ payment_status, status }) =>
        payment_status === "confirmed" &&
        status !== "cancelled" &&
        status !== "rejected"
    );
    return res ? "confirmed" : "pending";
  };

  const getActionItem = (item, allReg = {}) => {
    const registrants =
      approvalLevel === "INDIVIDUAL"
        ? allReg?.registrations
        : item?.registrations;
    const paymentStatus = getPaymentStatus(registrants);
    const allowApprove =
      paymentStatus === "confirmed" || !approvalOnPayment || !paidEvent;
    return (
      <Flex sx={{ gap: 2 }}>
        {allowApprove && (
          <Icon
            iconName="Accept"
            onClick={() => {
              setShowDialog();
              setItems(item);
              setApprove(true);
            }}
            sx={{ ...iconProps, ...{ color: "#28a745" } }}
          />
        )}
        {!allowApprove && (
          <Icon
            iconName="Accept"
            sx={{
              ...iconProps,
              ...{ color: "#c7c7c7", cursor: "default" },
            }}
          />
        )}
        <Icon
          iconName="Cancel"
          onClick={() => {
            setShowDialog();
            setItems(item);
            setApprove(false);
          }}
          sx={{ ...iconProps, ...{ color: "#dc3545" } }}
        />
      </Flex>
    );
  };

  const pnrLevelApprovalCols = [
    {
      key: "pnr",
      fieldName: "pnr",
      name: "PNR",
      data: "string",
      isResizable: true,
      minWidth: 90,
      maxWidth: 90,
    },
    {
      key: "registrations",
      fieldName: "registrations",
      name: "Registrant detail",
      data: "string",
      isResizable: true,
      minWidth: 1000,
      maxWidth: 1000,
    },
    {
      key: "action",
      fieldName: "action",
      name: "Action",
      data: "string",
      isResizable: true,
      minWidth: 100,
      onRender: (item) => (
        <React.Fragment>
          {checkPendingRegistrant(item?.registrations) && getActionItem(item)}
        </React.Fragment>
      ),
    },
  ];

  const individualLevelApprovalcol = [
    {
      key: "pnr",
      fieldName: "pnr",
      name: "PNR",
      data: "string",
      isResizable: true,
      minWidth: 90,
      maxWidth: 90,
    },
    {
      key: "registrations",
      fieldName: "registrations",
      name: "Registrant detail",
      data: "string",
      isResizable: true,
      minWidth: 1000,
      maxWidth: 1000,
    },
  ];

  const getCellVal = (key, val) => {
    let value = "";
    switch (key) {
      case "gender":
        value = genderValue(genderList, val) || "";
        break;
      case "ref":
        value = val === "B99999999" ? "" : val;
        break;
      case "date_of_joining":
      case "create_date":
        value = val ? moment(val).format("MMM DD, YYYY") : "-";
        break;
      default:
        value = val;
    }
    return typeof value === "string" ? value : "-";
  };

  const getRegistrant = (fields, data, allRegistrant) => {
    const row = fields.map((f) => {
      const key = f?.key || "";
      let value = data[key];

      if (key === "alt_mobile") {
        let resJson = {};
        if (typeof data?.reg_json === "string")
          resJson = JSON.parse(data?.reg_json);
        value = resJson?.alternate_phone;
      }
      if (!paidEvent && key === "payment_status") return null;
      if (key === "payment_status") value = getPaymentStatus(allRegistrant);
      const cellVal = getCellVal(key, value);
      const confirmStyle = cellVal === "confirmed" ? { color: "#28a745" } : "";
      if (key === "action" && data.status === "pending") {
        const activeRegistrant = {};
        activeRegistrant.registrations = [data];
        const allReg = {};
        allReg.registrations = allRegistrant;
        return (
          <li
            sx={{
              display: "inline-block",
              width: f?.width,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...confirmStyle,
            }}
          >
            <React.Fragment>
              {getActionItem(activeRegistrant, allReg)}
            </React.Fragment>
          </li>
        );
      }

      return (
        <li
          sx={{
            display: "inline-block",
            width: f?.width,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...confirmStyle,
          }}
          title={cellVal}
        >
          {cellVal}
        </li>
      );
    });
    return <ul sx={{ ...pendingRegUl }}>{row}</ul>;
  };

  const getRegistrantBlock = (data) => {
    const availableFields = [];
    let header = "";

    const isPendingRegExist = checkPendingRegistrant(data);
    if (isPendingRegExist) {
      header = viewFields.map((fields) => {
        availableFields.push(fields.key);
        if (!paidEvent && fields.key === "payment_status") return null;
        return (
          <li
            sx={{
              color: "#000",
              width: fields?.width,
              display: "inline-block",
            }}
          >
            {fields.label}
          </li>
        );
      });
    } else
      return (
        <li sx={{ color: "#000", width: "100%", display: "inline-block" }}>
          Processed
        </li>
      );

    let content = "";
    if (isPendingRegExist) {
      content = data.map((fields) => {
        if (fields?.status === "pending")
          return getRegistrant(viewFields, fields, data);
        return true;
      });
    }

    return (
      <div>
        <ul
          sx={{
            ...pendingRegUl,
            borderBottom: "1px solid #dedede",
            marginBlockStart: "0rem",
            paddingBottom: "0.5rem",
          }}
        >
          {header}
        </ul>
        {content}
      </div>
    );
  };

  const renderApprovalItemColumn = (item, index, column) => {
    const fieldContent =
      column && column.fieldName ? item[column.fieldName] : "";

    switch (column.key) {
      case "pnr":
        return fieldContent || "-";
      case "registrations":
        return getRegistrantBlock(fieldContent, item) || "-";
      default:
        return index;
    }
  };

  const setSearchCB = (d) => {
    setPage(1);
    setSearch(d);
  };

  const renderApprovalList = (
    <Box mt={3}>
      <Pagination
        page={page - 1}
        pageSize={pageSize}
        setPageSize={setPageSize}
        TotalPageCount={count}
        search={search}
        setSearch={setSearchCB}
        setPage={setPage}
        toSearch
      />
      {loader && (
        <div sx={{ ...spinerWrapper }}>
          <Spinner />
        </div>
      )}
      {!loader && registrations?.length > 0 && (
        <DetailsList
          columns={
            approvalLevel === "PNR"
              ? pnrLevelApprovalCols
              : individualLevelApprovalcol
          }
          items={registrations}
          setKey="set"
          selectionMode={SelectionMode.none}
          onRenderItemColumn={renderApprovalItemColumn}
          compact
          enableUpdateAnimations
        />
      )}
      {!loader && registrations?.length === 0 && (
        <Box sx={{ textAlign: "center", p: 3 }}>
          <h3>
            No{" "}
            {(() => {
              if (activeState === "pending") return " pending ";
              if (activeState === "cancelled") return " rejected ";
              return activeState;
            })()}
            {" records found. "}
          </h3>
        </Box>
      )}
    </Box>
  );

  return (
    <Container>
      <Box mt={3}>{renderApprovalList}</Box>
      {showDialog === null ? null : (
        <Box>
          <Dialog
            isOpen={!showDialog}
            type={DialogType.close}
            onDismiss={() => setShowDialog(null)}
            closeButtonAriaLabel="Close"
            // styles={appsDialogStyles.small}
            isBlocking
            dialogContentProps={{
              type: DialogType.normal,
              title: "Are you Sure ?",
            }}
          >
            <hr />
            {!!showDialog}
            <div sx={{ margin: "2em 0" }}>
              {approve === true ? (
                <>
                  <p> Do you want to Approve this participant.</p>
                  <DefaultButton
                    sx={{ mr: 2 }}
                    disabled={updating}
                    onClick={(e) => onApprove(e, items)}
                  >
                    Confirm
                  </DefaultButton>

                  <DefaultButton onClick={() => setShowDialog(null)}>
                    Cancel
                  </DefaultButton>
                </>
              ) : (
                <>
                  <p> Do you want to Reject this participant.</p>
                  <DefaultButton
                    sx={{ mr: 2 }}
                    disabled={updating}
                    onClick={(e) => onReject(e, items)}
                  >
                    Confirm
                  </DefaultButton>

                  <DefaultButton onClick={() => setShowDialog(null)}>
                    Cancel
                  </DefaultButton>
                </>
              )}
            </div>
          </Dialog>
        </Box>
      )}

      <Dialog
        isOpen={viewmore}
        type={DialogType.close}
        onDismiss={() => setViewmore(false)}
        title="Registrant Detail"
        isBlocking
        closeButtonAriaLabel="Close"
        styles={appsDialogStyles.mediumX}
      >
        <Box>
          <ViewRegistrant registrantData={items} fields={viewFields} />
        </Box>
      </Dialog>
    </Container>
  );
};

DashboardPendingApprovals.propTypes = {
  eventid: PropTypes.string.isRequired,
  approvalLevel: PropTypes.string.isRequired,
  paidEvent: PropTypes.bool.isRequired,
  approvalOnPayment: PropTypes.bool.isRequired,
};

export default DashboardPendingApprovals;
